.s-spaces {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--b-100);

  &.is-empty {
    display: flex;
    align-items: center;
    justify-content: center;

    .s-spaces-empty-state {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;
      gap: 24px;

      .empty-state-content {
        display: flex;
        flex-direction: column;
        gap: 20px;
        max-width: 348px;

        .empty-state-content-text {
          display: flex;
          flex-direction: column;
          gap: 16px;
        }

        .btn {
          margin-right: auto;
        }
      }
    }
  }

  .spaces-loader-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .spaces-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(317px, 1fr));
    gap: 16px;
    padding: 26px;

    .space-card {
      display: flex;
      flex-direction: column;
      border-radius: 16px;
      border: 1px solid transparent;
      height: 315px;
      cursor: pointer;
      transition: all 0.2s ease;
      background-color: var(--white);
      overflow: hidden;
      box-shadow: 0px 24px 94px 0px rgba(0, 0, 0, 0.07);

      &:hover {
        border: 1px solid var(--b-400);
        box-shadow: 0px 0px 0px 8px #1260eb28;

        .space-experience-preview-wrapper {
          &.index-0 {
            transform: translateX(-50%) translateZ(8px) scale(1.02) !important;
          }

          &.index-1 {
            transform: translateX(-50%) translateY(2px) scale(1.02)
              rotate(-2deg) !important;
          }

          &.index-2 {
            transform: translateX(-50%) translateY(2px) scale(1.02) rotate(2deg) !important;
          }
        }
      }

      .space-card-preview {
        height: 165px;
        flex-shrink: 0;
        border-radius: 12px;
        margin: 8px;
        overflow: hidden;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        .empty-space-preview {
          margin: auto;
          text-align: center;
        }

        .space-experience-preview-wrapper {
          position: absolute;
          height: 165px;

          &.index-0 {
            z-index: 1;
            top: 12px;
            left: 50%;
            transform: translateX(-50%);
            transition: all 0.5s ease;
          }

          &.index-1 {
            z-index: 0;
            top: 10px;
            left: calc(50% - 100px);
            transform: translateX(-50%);
            rotate: -16deg;
            transition: all 0.5s ease;
          }

          &.index-2 {
            z-index: 0;
            top: 50px;
            left: calc(50% + 100px);
            transform: translateX(-50%);
            rotate: 16deg;
            transition: all 0.5s ease;
          }
        }
      }

      .space-card-content {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 16px;

        .space-card-content-header {
          display: flex;
          align-items: center;
          gap: 12px;

          .icon-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 44px;
            height: 44px;
            border-radius: 12px;
            font-size: 20px;
            border: 1px solid var(--n-100);
            flex-shrink: 0;
          }

          .space-card-content-header-info {
            display: flex;
            flex-direction: column;
            gap: 4px;
            min-width: 0;
            .space-card-name {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }

        .space-card-description {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-word;

          &.no-description {
            color: var(--n-500);
            font-style: italic;
          }
        }
      }
    }
  }
}
