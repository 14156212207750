.block-settings.resource-center-trigger {
  .select-group-wrapper {
    .type-icon-select.jimo-select__single-value {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
  .position-picker {
    .position-icon-wrapper {
      display: flex;
      margin: auto 4px;
      width: 32px;
      height: 32px;
      box-sizing: border-box;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.04);
      border-radius: 12px;

      i {
        margin: auto 4px;
        font-size: 14px;
        width: 32px;
        box-sizing: border-box;
        border-radius: 12px;
        text-align: center;
      }
    }
  }

  .resource-center-trigger-position-picker {
    .alignment-figure-wrapper {
      .alignment-figure {
        margin: 40px 0;
      }
    }
  }
}
