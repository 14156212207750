.block-settings.resource-center-action-icon {
  .section-title-extra {
    .block-group {
      .icon-wrapper {
        background-color: var(--g-100);

        i {
          font-size: 10px;
          color: var(--n-800);
        }
      }
    }
  }

  .item-icon-picker {
    .preview-icon {
      display: flex;
      width: 32px;
      height: 32px;
      box-sizing: border-box;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.04);
      border-radius: 12px;

      svg {
        height: 24px;
        width: 24px;
        margin: auto;
      }
      img {
        margin: auto;
        width: 24px;
        height: 24px;
      }
    }
  }
}

.phosphor-list,
.twemoji-list {
  flex: 1;
  height: 400px; // Set a fixed height or use container constraints

  .phosphor-row,
  .twemoji-row {
    display: flex;
    gap: 8px;
    padding: 8px 0;
  }
}

.react-virtualized-auto-sizer {
  height: 100%;
  width: 100%;
}
