.block-resource-center-trigger-arrow {
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      transform: rotate(180deg);
    }
  }
}
