.modal-add-experience {
  max-height: 80%;
  padding: 0;
  width: 1020px;
  max-width: 80%;
  display: flex;
  flex-direction: column;
}

.modal-add-experience-content {
  display: flex;
  min-height: 0;

  .list-experience-wrapper {
    flex: 1 auto;
    overflow-y: auto;
    overflow-x: hidden;

    .search-wrapper {
      padding: 8px 10px;
    }

    .experiences-loader-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 500px;

      .loader-wrapper,
      .no-experiences-wrapper {
        margin: auto;
      }

      .experiences-list {
        overflow-y: auto;
        overflow-x: hidden;

        .experience {
          height: 200px;
          width: 100%;
          display: flex;
          padding: 16px 24px;
          position: relative;
          display: flex;
          cursor: pointer;
          box-sizing: border-box;

          &:not(:last-child) {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          }

          .loader {
            margin: auto;
          }

          .tag-view {
            padding: 8px;
            border-radius: 6px;
            font-size: 16px;
            font-weight: 500;
          }

          .preview {
            display: flex;
            width: 152px;
            flex-shrink: 0;
            position: relative;
            border-radius: 12px;
            background: #e6e6e6;
            transition: all 100ms ease-in;
            transform: scale(1);
            overflow: hidden;

            .poke-status-tag-wrapper {
              .status-tag {
                padding: 4px 8px;
                font-size: 10px;
                border-radius: 6px;
              }
            }

            .empty-steps {
              margin: auto;
            }

            .poke-preview-icon-wrapper {
              padding: 8px;
              position: absolute;
              background: rgba(0, 0, 0, 0.3);
              border-radius: 8px;
              right: 8px;
              bottom: 8px;
              color: #fff;
              display: none;
            }

            .live-preview {
              position: absolute;
              margin: auto;
              transform: scale(0.8);
              width: 100%;
              height: 100%;
            }

            .deprecated-wrapper {
              position: absolute;
              bottom: 0;
              left: 0;
              right: 0;
              border-radius: 8px;
              background: rgba(0, 0, 0, 0.68);
              display: flex;
              padding: 6px 8px;
              gap: 4px;
              margin: 8px;
              z-index: 1;

              .deprecated-label {
                margin: auto;
                color: #fff;
                font-size: 16px;
                font-family: Inter;
                font-weight: 500;
              }
            }
          }

          .infos {
            margin: auto;
            margin-left: 24px;

            .experience-title-wrapper {
              margin-bottom: 12px;

              .experience-title {
                align-items: center;
                margin-bottom: 8px;
                display: flex;
                gap: 8px;
                overflow-wrap: anywhere;

                .boosted-icon-wrapper {
                  box-sizing: border-box;
                  display: flex;
                  width: 24px;
                  height: 24px;
                  padding: 4px;
                  justify-content: center;
                  align-items: center;
                  gap: 10px;
                  border-radius: 8px;
                  background: #f5f2fe;

                  i {
                    font-size: 16px;
                    color: #bb68ff;
                  }
                }
              }
            }

            .poke-details-wrapper {
              display: flex;

              .tag-icon {
                display: flex;
                border-radius: 8px;
                border: 1px solid rgba(0, 0, 0, 0);
                padding: 8px;
                align-items: center;

                &.discovery {
                  background-color: #e6f5fd;
                }
                &.adoption {
                  background-color: #e6fde8;
                }

                i,
                img {
                  margin: auto;
                  margin-right: 8px;
                  font-size: 14px;
                }

                span {
                  font-size: 16px;
                }
              }

              .poke-type-wrapper {
                display: flex;
                flex-wrap: wrap;
                gap: 8px;

                .type-tag {
                  display: flex;
                  border-radius: 8px;
                  border: 1px solid rgba(0, 0, 0, 0.1);
                  padding: 8px;
                  font-size: 16px;

                  i {
                    margin: auto;
                    margin-right: 8px;
                    font-size: 14px;
                  }
                }
              }
            }
          }

          .audience-tags-wrapper {
            display: flex;
            gap: 8px;
            flex-flow: wrap;

            .poke-stats-wrapper {
              display: flex;
              gap: 4px;
              align-items: center;
              white-space: wrap;
              font-size: 12px;
            }
            .tag-view {
              margin-right: 12px;
              margin-left: 0px;
            }
          }

          .audience,
          .poke-environments {
            .poke-stats-wrapper,
            .poke-environments-content {
              display: flex;
              border: 1px solid rgba(0, 0, 0, 0.1);
              border-radius: 16px;
              padding: 4px 8px;
              font-size: 12px;

              .audience-string {
                flex: 1;
              }
              i {
                margin-right: 4px;
                display: flex;
                align-items: center;
                font-size: 16px;
              }
            }
          }

          .checkbox-wrapper {
            display: flex;
            margin: auto 0;
            flex-shrink: 0;

            i {
              font-size: 20px;

              &.icon-checkbox {
                color: var(--b-400);
              }
              &.icon-checkbox-o {
                color: var(--n-400);
              }
            }
          }
        }
      }
    }
  }
}
