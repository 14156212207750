.element-selector-wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;

  .selector-auto-group {
    display: flex;
    gap: 12px;

    .selector-auto-option {
      flex: 1 1 0;
      display: flex;
      flex-direction: column;
      padding: 8px 12px;
      gap: 6px;
      background: linear-gradient(180deg, #f7f7f7 0%, #ffffff 111.05%);
      border-radius: 8px;
      border: 1px dashed var(--n-300);
      cursor: pointer;
      text-align: center;
      align-items: center;

      .icon-wrapper {
        display: flex;
        width: 36px;
        height: 36px;
        border-radius: 8px;
        background-color: white;
        box-shadow: 0px 4px 4px 0px #00000014;

        i {
          margin: auto;
        }
      }

      .preview-wrapper {
        display: flex;

        img {
          margin: auto;
          max-height: 36px;
          max-width: 100%;
          object-fit: contain;
          border-radius: 8px;
        }

        .image-not-found {
          display: flex;
          gap: 4px;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  .selector-preview-wrapper {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background: var(--n-100);
    overflow: hidden;
    border: 1px solid var(--n-300);

    .image-not-found {
      display: flex;
      gap: 8px;
      justify-content: center;
      align-items: center;
      height: 120px;
    }

    img {
      max-width: 100%;
      height: 120px;
      object-fit: contain;
      margin: 8px;
    }

    .query-selector {
      display: flex;
      padding: 8px 12px;
      // border-top: 1px solid var(--n-300);
    }
  }

  .select-element-btn-wrapper {
    display: flex;
    gap: 8px;

    .btn {
      flex: 1 1 0;
      justify-content: center;
      text-wrap: nowrap;

      i.isax {
        font-size: 14px;
      }
    }
  }

  .strictness-slider {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .strictness-title {
      display: flex;
      gap: 8px;
    }

    .rc-slider {
      margin: auto;

      .rc-slider-rail {
        height: 2px;
        background: rgba(0, 0, 0, 0.1);
      }

      .rc-slider-track {
        height: 2px;
        background: #1260eb;
      }

      .rc-slider-handle {
        width: 16px;
        height: 16px;
        margin-top: -8px;
        border-radius: 50%;
        border: 0;
        opacity: 1;
        background: #ffffff;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
      }
    }
  }
}
