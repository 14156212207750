.timeline-item-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;

  .sub-timeline-trait {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 15px;
    width: 2px;
    height: calc(100% - 16px);
    background-color: rgba($color: #000000, $alpha: 0.1);
  }
  .timeline-item {
    display: flex;
    flex-direction: column;
    padding: 12px 16px;
    background: #ffffff;
    box-shadow: 0px 0px 94px 0px #0000000a;
    border-radius: 16px;

    &.interactive {
      cursor: pointer;
    }

    .item-infos {
      display: flex;
      flex: 1 auto;
      gap: 12px;

      .icon-wrapper {
        border-radius: 12px;
        height: 40px;
        width: 40px;
        display: flex;
        position: relative;
        flex-shrink: 0;

        &.scratch {
          background-color: #fff;
          border: 1px solid rgba(0, 0, 0, 0.1);
        }
        &.survey {
          background-color: var(--b-200);
        }
        &.adoption {
          background-color: var(--g-200);
        }
        &.changelog {
          background-color: var(--b-150);
        }
        &.user {
          background-color: var(--y-200);
        }

        i {
          margin: auto;
          font-size: 20px;
        }

        .count-wrapper {
          position: absolute;
          right: -6px;
          bottom: -6px;
          width: 24px;
          height: 24px;
          background: #111c39;
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.12);
          border-radius: 11px;
          display: flex;

          .count {
            margin: auto;
            color: #fff;
            font-family: 'Inter';
            font-weight: 500;
            font-size: 14px;
          }
        }
      }

      .item-content-wrapper {
        display: flex;
        flex-direction: column;
        gap: 4px;
        flex: 1;
        min-width: 0;

        .item-title {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .item-subtitle {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .actions-wrapper {
        margin-left: auto;
        display: flex;

        .show-portal-details {
          border: 0;
          padding: 8px;

          i {
            margin: 0;
            transition: transform 0.3s ease-in-out;
          }

          &.open {
            i {
              transform: rotate(90deg);
            }
          }
        }

        .tour-progress-wrapper {
          display: flex;
          margin: auto;

          .tour-progress {
            font-size: 16px;
          }

          .circular-progress-wrapper {
            height: 32px;
            width: 32px;
            margin-left: 8px;
            position: relative;

            .icon-tick-overlay {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;

              i.icon-tick {
                font-size: 14px;
                color: #03a527;
              }
            }
          }
        }
      }

      .time {
        margin: auto;
      }
    }

    .extra {
      margin-top: 8px;
      .comment {
        font-weight: 300;
      }
    }
  }
}
