.block-resource-center-container {
  display: flex;

  .block-resource-center {
    width: 360px;
    transition: width 200ms ease, background-color 500ms ease;
    display: flex;
    flex-direction: column;
    animation: fadeInUpSpring 600ms ease forwards;
    overflow: hidden;

    &.is-mini {
      animation: fadeOutDown 200ms ease forwards;
      pointer-events: none;
    }

    &.disable-animations {
      animation: none !important;

      &.is-mini {
        opacity: 0;
        height: 0;
      }

      * {
        animation: none !important;
      }
    }

    &.is-success {
      overflow: hidden;
    }
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background: #0000001a;
    border-radius: 6px;
  }
}

.poke-block-clickable {
  cursor: pointer;
  position: relative;
}

@keyframes fadeInUpSpring {
  0% {
    opacity: 0;
    transform: translateY(40%);
  }
  40% {
    opacity: 1;
    transform: translateY(-1%);
  }
  70% {
    transform: translateY(1%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOutDown {
  0% {
    opacity: 1;
    transform: translateY(0);
    height: auto;
  }
  99% {
    opacity: 0;
    transform: translateY(20%);
    height: auto;
  }
  100% {
    opacity: 0;
    transform: translateY(20%);
    height: 0;
  }
}
