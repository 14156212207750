.block-settings.resource-center-group {
  .section-title-extra {
    .block-group {
      .icon-wrapper {
        background-color: var(--p-200);

        i {
          font-size: 10px;
          color: var(--n-800);
        }
      }
    }
  }
}
