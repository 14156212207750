.block-resource-center-trigger-checklist-progress-wrapper {
  position: absolute !important;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 300ms ease-in-out;
  z-index: 1;

  .divider {
    width: 1px;
    opacity: 0.2;
  }

  .block-resource-center-trigger-checklist-progress {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
