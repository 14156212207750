@import './../../Colors';

$jimoNavbarWidth: 230px;

.jimo-navbar {
  width: $jimoNavbarWidth;
  height: 100%;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex: 0 0 $jimoNavbarWidth;
  flex-direction: column;
  box-sizing: border-box;
  border-right: 1px solid rgba(0, 0, 0, 0.1);

  .navbar-beta-label {
    background-color: #1260eb;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 8px 8px;
    text-align: center;
    color: #fff;
    font-size: 12px;
  }

  .navbar-dropdown-trigger {
    .content-wrapper {
      display: flex;
      padding: 8px;
      border-radius: 12px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      flex: 1 0 0;
      cursor: pointer;

      &:hover {
        background-color: var(--n-100);
      }

      .content {
        display: flex;
        align-items: center;
        gap: 8px;
        flex: 1 0 0;

        .nav-project-logo-wrapper {
          width: 28px;
          display: flex;
          align-items: center;
          justify-content: center;

          .nav-project-logo {
            width: 20px;
            height: 20px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            border-radius: 6px;
            justify-content: center;
            align-items: center;
            display: flex;
            font-size: 14px;
            color: #f0f2f4;
            text-transform: uppercase;
            font-weight: 500;
          }
        }

        .names {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 4px;
          flex: 1 0 0;
        }
      }

      i.icon-chevron-bottom {
        font-size: 10px !important;
      }
    }
  }
  .jimo-icon-wrapper {
    cursor: pointer;
  }

  .menu-wrapper {
    font-weight: 500;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 1 auto;
    overflow-y: auto;
    margin-top: 8px;

    .main-menu {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 4px;
      align-self: stretch;

      .section {
        display: flex;
        padding: 0 8px;
        flex-direction: column;
        align-items: flex-start;
        gap: 2px;
        align-self: stretch;

        & > * {
          align-self: stretch;
        }

        i {
          font-size: 20px;
        }

        .space-icon-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 20px;
        }
      }
    }

    .bottom-menu {
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-self: stretch;
      padding: 8px;
      border-top: 1px solid var(--n-300);

      .support-menu-wrapper {
        display: flex;
        justify-content: space-between;
        gap: 8px;
        align-self: stretch;

        & > * {
          width: 100%;
        }

        .menu-item {
          display: flex;
          height: 32px;
          width: 100%;
          padding: 8px;
          justify-content: center;
          align-items: center;
          gap: 12px;
          border-radius: 8px;
          background: var(--n-50);
          color: var(--n-700);
          transition: 200ms all ease-in-out;

          &:hover {
            background: var(--n-100);

            i {
              color: var(--n-800);
            }
          }

          i {
            display: block;
            font-size: 16px;
            margin-right: 0;
            color: #4d637b;
          }

          .icon-wrapper {
            width: unset;
            margin-right: 0;
          }
          .whats-new-badge {
            position: absolute;
            left: 37px;
            top: 26px;
            width: 10px;
            height: 10px;
            background-color: #ff4545;
            border-radius: 50%;
          }
        }
      }

      .dropdown-trigger {
        display: block !important;
      }
    }

    .menu-item {
      position: relative;
      background-color: none;
      transition: 100ms all ease-in-out;
      color: var(--n-700);
      border-radius: 12px;
      font-size: 14px;
      font-family: 'Inter', sans-serif;
      padding: 8px;
      height: 37px;
      box-sizing: border-box;

      &:hover {
        color: var(--n-800);
        background: var(--n-100);
      }
      &.coming-soon {
        pointer-events: none;
        position: relative;
        color: rgba(13, 13, 13, 0.3);
        cursor: default;
        &:hover {
          background-color: transparent;
        }

        .label {
          margin-left: 8px;
        }
      }
      &.is-active {
        color: #1260eb;
        background: rgba(18, 96, 235, 0.05);
      }
      &.navbar-get-started-link {
        background-color: transparentize($color: $colorPrimary, $amount: 0.5);
        color: #fff;
        &:hover {
          background-color: $colorPrimary;
        }
        &.is-active {
          background-color: $colorPrimary;
        }

        i {
          margin-bottom: 8px;
        }
      }
      &.navbar-get-started {
        padding: 8px;
        justify-content: center;

        .text-icon-wrapper {
          display: flex;
          justify-content: space-between;
          flex: 1;
          align-items: center;

          i {
            padding: 8px;
            font-size: 12px;
            transition: all 100ms ease-in-out;
            &.rotated {
              transform: rotate(180deg);
            }
          }
        }

        .progress-label {
          display: flex;
          padding: 4px 8px;
          align-items: center;
          gap: 8px;
          border-radius: 16px;
          background: var(--blue-400-main, #1260eb);
          color: var(--neutral-white, var(--neutral-neutral-0, #fff));
          font-family: Inter;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%; /* 18px */
        }
      }
      &.menu-item-spaces {
        &:hover {
          .add-space-wrapper {
            opacity: 1;
          }
        }
        .expand-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 20px;
          height: 20px;
          margin-right: 13px;
          margin-left: 3px;
          border-radius: 4px;

          &.is-expanded {
            i {
              transform: rotate(90deg);
            }
          }

          i {
            font-size: 14px;
            margin-right: 0;
            transition: transform 100ms ease-in-out;
          }
        }

        .add-space-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 20px;
          height: 20px;
          margin-left: auto;
          opacity: 0;
          transition: 100ms opacity ease-in-out;

          i.isax-add {
            margin-right: 0;
          }
        }
      }

      .icon-wrapper {
        display: flex;
        width: 28px;
        margin-right: 8px;
        flex-shrink: 0;

        i {
          margin: auto;
        }

        .circular-progress {
          position: relative;
          margin: auto;
        }

        .circular-progress-wrapper {
          margin: auto;
          height: 20px;
          width: 20px;
        }
      }

      .space-name-wrapper {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .space-list-wrapper {
      display: flex;
      flex-direction: column;
      gap: 4px;
      align-items: flex-start;
      align-self: stretch;

      & > * {
        align-self: stretch;
      }
    }
  }
  .navbar-get-started-sub-menu {
    .menu-item {
      padding-left: 76px;
      padding-top: 10px;
      padding-bottom: 10px;
      color: rgba(0, 0, 0, 0.5);
      &:hover {
        color: rgba(0, 0, 0, 0.8);

        .circular-progress {
          opacity: 0.5;
        }
      }
      &.is-active {
        color: rgba(0, 0, 0, 1);

        .circular-progress {
          opacity: 1;
        }
      }
      &.is-disabled {
        opacity: 0.4;
        cursor: not-allowed;
      }
    }
    .navbar-numbered-circular-progress {
      position: absolute;
      top: 4px;
      left: 8px;
      font-family: 'Montserrat', sans-serif;
      font-weight: 600;
      &.is-one {
        span {
          left: 27px;
          top: 7px;
        }
      }

      .circular-progress {
        margin-right: 0 !important;
        top: 0;
        opacity: 0.1;
      }
      span {
        position: absolute;
        left: 25px;
        top: 7px;
      }
    }
  }
  .icon-exclamation {
    padding: 0 6px;
  }
  .icon-goto {
    font-size: 8px;
    padding: 0 4px;
  }
  .app-version {
    color: rgba(0, 0, 0, 0.25);
    font-weight: 500;
    font-size: 8px;
    text-align: center;
  }
}

.jimo-navbar.compact {
  width: 56px;
  flex: 0 0 56px;

  .navbar-dropdown-trigger {
    margin: auto;

    .content-wrapper {
      display: flex;
      box-sizing: border-box;
      padding: 8px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      flex: 1 0 0;
      cursor: pointer;

      .content {
        display: flex;
        align-items: center;
        gap: 8px;
        flex: 1 0 0;
      }
    }
  }

  .nav-project-logo {
    margin: auto;
  }
  .menu-wrapper {
    .divider {
      width: 100%;
    }

    .main-menu {
      .navbar-get-started {
        padding: 12px 0;
      }
      .icon-wrapper {
        width: unset;
        margin: auto;
      }
      .menu-item {
        &.coming-soon {
          &::before {
            display: none;
          }
        }
      }
    }
    .divider {
      margin: 0;
    }
  }
  .bottom-menu {
    display: none;
  }
}

.menu-dropdown-content {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.navbar-dropdown-content {
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.04);
  border-radius: 12px !important;
  animation: fade-in 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.support-menu-tooltip-content {
  border-radius: 16px !important;
  max-width: 400px;

  .tooltip-children {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

.menu-item.badge-new-content {
  &::after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--r-400);
    top: 10px;
    right: 14px;
    animation: whats-new-animation 1.2s cubic-bezier(0.215, 0.61, 0.355, 1)
      infinite;
  }
}

@keyframes whats-new-animation {
  0% {
    box-shadow: 0 0 0 0px var(--r-400);
  }
  100% {
    box-shadow: 0 0 0 6px transparent;
  }
}
