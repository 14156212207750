.amplitude-settings {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  gap: 24px;
  &.is-loading {
    justify-content: center;
    align-items: center;
  }

  .amplitude-settings-header {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .left-side {
      display: flex;
      gap: 16px;
      align-items: center;
    }
    .right-side {
      display: flex;
      gap: 16px;
      align-items: center;
    }
  }

  .amplitude-settings-content {
    display: flex;
    gap: 24px;
    align-self: stretch;

    & > .left-side {
      flex: 1;
      gap: 24px;
      display: flex;
      flex-direction: column;
    }
    & > .right-side {
      flex: 0 0 360px;
      display: flex;
      flex-direction: column;
      gap: 24px;

      .alert-body span {
        color: var(--b-400);
        font-weight: 600;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
          text-underline-offset: 3px;
        }
      }
    }

    .settings-jimo-to-amplitude,
    .settings-amplitude-to-jimo {
      display: flex;
      flex: 1;
      align-items: center;

      .left-side {
        flex: 1;

        .title-wrapper {
          display: flex;
          align-items: center;
          display: flex;
          gap: 4px;
        }
      }
    }
  }
}
