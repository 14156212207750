.quick-access {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  margin-bottom: 16px;
  padding: 0 32px;

  .quick-access-item {
    display: flex;
    gap: 12px;
    border-radius: 14px;
    position: relative;
    cursor: pointer;
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
    padding: 16px;
    color: #fff;
    border: 1px solid transparent;
    transition: all 200ms ease;
    align-items: center;
    &:hover {
      .dismiss-btn {
        opacity: 1;
      }
    }
    &.how-to-builder {
      background-image: url(./images/quick-access-1.svg);
      border-color: var(--b-200);
      &:hover {
        filter: brightness(1.05);
      }

      .title {
        max-width: 80%;
      }
    }
    &.start-with-templates {
      color: var(--n-800);
      background-image: url(./images/quick-access-2.svg);
      border-color: var(--o-300);
      &:hover {
        filter: brightness(1.05);
      }

      .title {
        max-width: 165px;
      }
    }
    &.book-expert-call {
      color: var(--n-800);
      background-image: url(./images/quick-access-3.svg);
      border-color: var(--n-300);
      &:hover {
        filter: brightness(1.05);
      }

      .title {
        max-width: 70%;
      }
    }

    .icon-wrapper {
      background-color: #fff;
      border-radius: 12px;
      font-size: 24px;
      padding: 6px;
      color: #000;
    }

    .dismiss-btn {
      position: absolute;
      right: -14px;
      top: -14px;
      z-index: 2;
      display: flex;
      padding: 10px;
      align-items: flex-start;
      gap: 8px;
      border-radius: 50%;
      background: #fff;
      color: var(--n-800);
      box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.07);
      opacity: 0;
      transition: all 100ms ease-in-out;
      border: 1px solid var(--n-300) !important;

      i {
        filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.04));
        font-size: 14px;
      }
    }
  }
}

.modal-arcade {
  width: unset !important;
}
