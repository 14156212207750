.users-responses-drawer {
  overflow: hidden;
  margin: 8px;
  right: 0 !important;
  top: 0 !important;
  left: unset !important;
  position: absolute;
  height: calc(100% - 16px);
  width: 756px !important;
  display: flex;
  flex-direction: column;
  padding: 0;
  border-radius: 12px;

  .modal-title {
    padding: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-weight: 500;
    font-size: 24px;
    font-family: 'Montserrat';
    margin-bottom: 0;
    min-height: 0;
    flex: 0 0 auto;

    .infos {
      display: flex;
      align-items: center;
      gap: 16px;

      &.opinion-scale {
        .icon-wrapper {
          background-color: #fdefe6;
        }
      }
      &.multiple-choice {
        .icon-wrapper {
          background-color: #e6f5fd;
        }
      }
      &.text-long {
        .icon-wrapper {
          background-color: #e6fde8;
        }
      }
      &.slider {
        .icon-wrapper {
          background-color: #e6fde8;
        }
      }
      &.nps {
        .icon-wrapper {
          background-color: #fdefe6;
        }
      }
      &.concept-test {
        .icon-wrapper {
          background-color: #e6f5fd;
        }
      }

      .icon-wrapper {
        display: flex;
        padding: 8px;
        align-items: flex-start;
        gap: 8px;
        height: 48px;
        width: 48px;
        box-sizing: border-box;
        border-radius: 12px;
        background-color: white;

        i {
          color: black;
          margin: auto;
          font-size: 24px;
        }
      }
      .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 8px;

        .title {
          color: var(--content-secondary, #4d637b);
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%; /* 24px */
        }
        .question {
          color: var(--content-primary, #071331);
          font-family: Montserrat;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 125%; /* 25px */
        }
      }
    }
  }

  .content-wrapper {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding: 24px;
    background: #f8f8f8;
    gap: 12px;

    .users-table-wrapper {
      overflow: hidden;
      display: flex;
      box-sizing: border-box;
      position: relative;
      flex: 1 auto;
      border-radius: 12px;
      background: #fff;
      box-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.05);

      .users-table {
        width: 100%;
        display: flex;
        flex-direction: column;

        & > div {
          padding: 0 24px;
        }

        .loader-wrapper {
          margin: auto;
        }

        .users-header {
          display: flex;
          padding: 12px 16px;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;
          border-bottom: 1px solid var(--border-minimal, #e5e5e5);

          .dropdown-trigger {
            margin-right: 16px;
            &.is-set {
              .btn {
                border-color: #1260eb66;
                background-color: #1260eb0f;
                color: #1260eb;

                i {
                  color: #1260eb !important;
                }
              }
            }

            i.icon-left {
              border-right: 1px solid rgba(0, 0, 0, 0.1);
              margin-right: 8px !important;
              padding-right: 8px;
            }
            i.icon-right {
              font-size: 8px !important;
              color: #b6b6b6;
              margin-left: 10px !important;
            }
          }

          .users-actions {
            margin-left: auto;
          }
        }

        .list-open-question-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 12px;
          padding: 24px;
          align-self: stretch;
          overflow-y: auto;

          .response {
            display: flex;
            padding: 16px;
            align-items: center;
            gap: 32px;
            align-self: stretch;
            border-radius: 12px;
            background: var(--neutral-neutral-50, #fbfbfb);
            cursor: pointer;

            &:hover {
              .actions {
                opacity: 1 !important;
              }
            }

            .content {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              gap: 12px;
              flex: 1 0 0;

              .value {
                align-self: stretch;
                color: var(--content-primary, #071331);
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 150%; /* 24px */
              }

              .info {
                display: flex;
                align-items: center;
                gap: 24px;
                align-self: stretch;
                position: relative;

                .info-content {
                  display: flex;
                  align-items: center;
                  gap: 24px;
                  flex: 1;

                  .user-info,
                  .time-info {
                    display: flex;
                    align-items: center;
                    gap: 4px;

                    i {
                      color: #4d637b;
                      margin-right: 4px;
                    }

                    .username {
                      color: var(--content-secondary, #4d637b);
                      font-family: Inter;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: 150%; /* 21px */
                    }

                    .anonymous-username {
                      color: var(--content-tertiary, #9ca1ad);
                      font-family: Inter;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: 150%;
                    }

                    .email-info {
                      color: var(--content-secondary, #4d637b);
                      font-family: Inter;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 150%; /* 21px */
                    }

                    .time {
                      color: var(--content-secondary, #4d637b);
                      font-family: Inter;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: 150%; /* 21px */
                    }
                  }
                  .response-actions {
                    margin-left: auto;
                  }
                }

                .actions {
                  display: flex;
                  opacity: 0;
                  transition: all 200ms ease-in-out 0s;
                  gap: 12px;
                  position: absolute;
                  right: 0;

                  .btn {
                    text-wrap: nowrap;
                  }
                }
              }
            }
          }
        }

        .users-list {
          height: 100%;
          display: flex;
          flex-direction: column;
          overflow-y: auto;

          .top {
            flex: 0 1 auto;
            display: grid;
            grid-template-columns: 10% 30% 40% 20%;
            padding: 16px 12px 12px 12px;
            box-sizing: border-box;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: rgba(0, 0, 0, 0.4);
          }

          .loader-wrapper {
            display: flex;
            justify-content: center;
            padding-top: 24px;
          }
          .list {
            flex: 1 0 auto;
            overflow: auto;
            flex: 1 1 100%;
            box-sizing: border-box;

            .card-user {
              position: relative;
              border-radius: 12px;
              padding: 12px;
              display: grid;
              grid-template-columns: 10% 30% 40% 20%;
              align-items: center;
              transition: all 300ms ease 0s;
              cursor: pointer;
              &:hover {
                background: #f3f7fe;

                .response-actions {
                  display: flex;
                }

                .avatar {
                  scale: 1.25;
                }

                .username-wrapper {
                  .username {
                    color: #1260eb;
                  }
                }
                .user-tags {
                  .tags-list-wrapper {
                    border-color: rgba(0, 0, 0, 0.1);
                  }
                  .icon-chevron-bottom {
                    color: #b6b6b6;
                  }
                }
                .user-actions {
                  opacity: 1;
                }
              }

              &:not(:last-child) {
                margin-bottom: 16px;
              }

              .user-avatar-wrapper {
                .avatar {
                  height: 40px;
                  width: 40px;
                  transition: all 300ms ease-in-out 0s;
                }
              }

              .username-wrapper {
                display: flex;
                flex-direction: column;

                .username {
                  text-align: left;
                  display: flex;
                  flex-direction: column;
                  overflow: hidden;
                  color: #000;
                  font-family: Inter;
                  font-size: 16px;
                  font-weight: 500;
                }

                .anonymous-name,
                .email-info {
                  color: rgba(0, 0, 0, 0.5);
                  font-family: Inter;
                  font-size: 14px;
                  font-weight: 400;
                }
              }
              .user-response {
                .nps-wrapper {
                  display: flex;
                  margin: auto 0;
                  margin-left: 8px;
                  align-items: center;

                  .nps {
                    margin-left: 6px;
                    padding: 10px 8px;
                    font-weight: 500;
                    font-size: 16px;
                    border-radius: 4px;

                    &.detractor-1 {
                      background: #fde6e6;
                      color: #aa1616;
                    }
                    &.detractor-2 {
                      background: #fdefe6;
                      color: #aa5d16;
                    }
                    &.passive {
                      background: #e6f5fd;
                      color: #1260eb;
                    }
                    &.promoter {
                      background: #e6fde8;
                      color: #159201;
                    }
                  }
                }

                .slider-wrapper {
                  .custom-slider {
                    padding: 0;
                    padding-right: 10px;

                    .rc-slider-track {
                      background: #85b1ff;
                    }

                    .rc-slider-handle {
                      border: solid 2px #1260eb;
                      opacity: 1;
                    }
                  }
                }

                .opinion-wrapper {
                  font-size: 24px;
                }
              }
              .user-response-date {
                display: flex;
                align-items: center;
                gap: 12px;

                .content {
                  display: flex;
                  flex-direction: column;
                  gap: 2px;

                  .event-date {
                    color: rgba(0, 0, 0, 0.5);
                    font-family: Inter;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                  }
                }
              }

              .response-actions {
                position: absolute;
                right: 8px;
                display: none;
                gap: 8px;
              }

              .user-tags {
                display: flex;

                .tags-list-wrapper {
                  border-color: transparent;
                  transition: all 300ms ease 0s;
                }
                .icon-chevron-bottom {
                  color: transparent;
                  transition: all 300ms ease 0s;
                }
              }
              .user-activity {
                display: flex;
                flex-direction: column;
              }
              .user-btn-profile {
                display: flex;
                justify-content: center;
              }
              .icon-close {
                opacity: 0.1;
              }
              .user-actions {
                opacity: 0;
              }
            }
          }
        }

        .users-pagination {
          border-top: 1px solid var(--border-minimal, #e5e5e5);
          padding: 12px 16px;

          .actions-wrapper {
            display: flex;
            gap: 4px;
            margin-left: auto;

            .btn {
              margin: 0;
              padding: 4px 8px;

              i {
                font-size: 16px;
              }
              .isax-arrow-left-2 {
                // font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}

.users-responses-dropdown-content {
  min-width: 158px;
  background: #ffffff;
  box-shadow: 0px 14px 19px rgb(0 0 0 / 3%);
  border-radius: 8px;
  margin: 4px auto;

  .setting-list {
    max-height: 200px;
    padding: 4px;
    overflow-y: auto;

    .list-empty-state {
      opacity: 0.3;
      padding: 8px;
      font-size: 12px;
      text-align: center;
    }

    .setting-item {
      display: flex;
      align-items: center;
      padding: 8px;
      font-weight: 400;
      font-size: 14px;
      border-radius: 8px;
      font-family: 'Montserrat';
      font-weight: 500;
      cursor: pointer;
      &:hover {
        background-color: #1260eb0f;
      }
      &.is-set {
        color: #1260eb;
      }

      i {
        margin-right: 8px;
        font-size: 14px;
      }

      .option-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 2px;
        flex: 1 0 0;

        .option-label {
          color: var(--content-primary, #071331);
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%; /* 21px */
        }

        .option-additional {
          overflow: hidden;
          color: var(--content-secondary, #4d637b);
          text-overflow: ellipsis;
          font-family: Inter;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%; /* 18px */
        }
      }
    }
  }
}
