.block-settings.resource-center-trigger-icon {
  .item-icon-picker {
    .preview-icon {
      display: flex;
      width: 32px;
      height: 32px;
      box-sizing: border-box;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.04);
      border-radius: 12px;

      svg {
        height: 24px;
        width: 24px;
        margin: auto;
      }
      img {
        margin: auto;
        width: 24px;
        height: 24px;
      }
    }
  }
}
