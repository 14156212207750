.s-settings-webhook-details {
  display: flex;
  flex-direction: column;

  .webhook-setup-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 34px;
    flex-grow: 0;

    .left-wrapper {
      display: flex;
      align-items: center;
      gap: 24px;

      .btn {
        i {
          font-size: 14px;
        }
      }

      .status-wrapper {
        display: flex;
        align-items: center;
        gap: 12px;
      }

      .webhook-status {
        background-color: var(--o-100);
        color: var(--o-500);
        padding: 0px 12px;
        border-radius: 12px;
        font-size: 12px;
        text-transform: capitalize;
        &.is-live {
          background-color: var(--g-100);
          color: var(--g-500);
        }
      }
    }

    .right-wrapper {
      display: flex;
      gap: 12px;
      align-items: center;

      .btn-more {
        padding: 12px 16px;
      }
    }
  }

  .webhook-details {
    display: flex;
    flex: 1;
    &.is-empty {
      align-items: center;
      justify-content: center;
      text-align: center;

      .subtitle-2 {
        margin: 20px 0;
      }
    }

    .details-content {
      flex: 1;
      display: flex;
      flex-direction: column;

      .triggers-filters {
        display: flex;
        gap: 12px;
        margin-bottom: 24px;
        flex-grow: 0;

        .webhook-filter {
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 8px 12px;
          border-radius: 16px;
          border: 1px solid var(--n-100);
          background-color: #fff;
          font-size: 16px;
          cursor: pointer;
          &:hover {
            color: var(--b-400);
          }
          &.is-active {
            background-color: transparent;
            border-color: var(--b-400);
            color: var(--b-400);
          }

          i {
            font-size: 20px;
          }
        }
      }

      .details-wrapper {
        display: grid;
        grid-template-columns: 50% 50%;
        gap: 4px;

        .trigger-details {
          height: calc(100vh - 140px);
          overflow-y: auto;
          box-sizing: border-box;
          background-color: #fff;
          border-radius: 16px;
          padding: 16px;
          &.is-empty {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;

            .body-2 {
              margin-top: 12px;
              max-width: 50%;
            }
          }

          .response-empty {
            background-color: var(--n-50);
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 24px;
            border-radius: 12px;
            color: var(--n-500);
            margin-top: 8px;
          }

          .trigger-header {
            display: flex;
            align-items: center;
            gap: 8px;
            margin-bottom: 24px;

            .trigger-icon {
              padding: 6px;
              border: 1px solid var(--n-300);
              border-radius: 8px;
              font-size: 18px;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .trigger-name-wrapper {
              flex: 1;
              display: flex;
              flex-direction: column;
              gap: 4px;
            }

            .trigger-retry-wrapper {
              display: flex;
              align-items: center;
              gap: 8px;

              .btn {
                font-size: 12px;

                i {
                  font-size: 16px;
                }
              }
            }
          }

          pre {
            background-color: var(--n-800) !important;
            border-radius: 12px;
            padding: 24px !important;
          }

          code {
            background-color: unset;
            color: unset;
          }

          .trigger-response {
            margin-bottom: 24px;

            .subtitle-3 {
              margin-bottom: 12px;
            }
            .response-status {
              display: flex;
              justify-content: space-between;
            }
          }
          .trigger-request {
            .subtitle-3 {
              margin-bottom: 12px;
            }
          }
        }

        .trigger-list-wrapper {
          display: flex;
          flex-direction: column;
          max-height: calc(100vh - 120px);
          overflow-y: auto;
          padding-right: 4px;

          .trigger-list {
            align-self: flex-start;
            flex: 1;
            width: 100%;
            position: relative;
            padding-left: 24px;
            padding-right: 20px;
            box-sizing: border-box;

            .trigger-section {
              padding-bottom: 24px;
              position: relative;
              &:not(:last-child)::before {
                position: absolute;
                display: block;
                left: -18px;
                top: 2px;
                content: '';
                width: 2px;
                height: calc(100%);
                border-radius: 4px;
                background-color: var(--n-300);
              }

              .section-date {
                display: flex;
                align-items: center;
                margin-bottom: 16px;

                .date-marker {
                  width: 8px;
                  height: 8px;
                  background-color: #000;
                  border-radius: 50%;
                  border: 4px solid var(--n-300);
                  position: absolute;
                  left: -25px;
                  top: 2px;
                }
              }

              .section-triggers {
                display: flex;
                flex-direction: column;
                gap: 12px;
              }

              .trigger-item {
                display: flex;
                align-items: center;
                background-color: #fff;
                padding: 12px 20px;
                border-radius: 16px;
                gap: 12px;
                border: 1px solid transparent;
                cursor: pointer;
                transition: all 100ms ease;
                &:hover {
                  box-shadow: 0px 0px 0px 3px #1260eb1a;
                }
                &.is-active {
                  border: 1px solid var(--b-400);
                  box-shadow: 0px 0px 0px 3px #1260eb1a;
                }

                .trigger-status {
                  display: flex;
                  align-items: center;
                  font-size: 24px;
                  color: var(--g-400);
                  &.is-fail {
                    color: var(--r-400);
                  }
                }

                .trigger-name {
                  display: flex;
                  align-items: center;
                  flex: 1;
                }

                .trigger-icon {
                  border: 1px solid var(--n-300);
                  border-radius: 8px;
                  font-size: 20px;
                  padding: 6px;
                  margin-left: 4px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
              }
            }
          }
        }
      }
    }
  }
}
