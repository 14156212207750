.resource-center-group {
  display: flex;
  flex-direction: column;

  &.is-grid {
    .resource-center-group-content {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

  .resource-center-group-content {
    display: flex;
    flex-direction: column;
  }
}
