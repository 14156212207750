@import './../../Mixin';

.input-group {
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 4px;

  // Legend
  .input-legend {
    &.disabled {
      color: var(--n-500);
    }
  }

  // Main wrapper
  .input-main-wrapper {
    height: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 0;
    border: 1px solid var(--n-300);
    border-radius: 12px;
    // overflow: hidden; // Don't uncomment, we have popup rendered inside inputs that will be hidden if enabled
    transition: all 200ms ease;

    &.small {
      border-radius: 8px;
    }
    &:not(.disabled) {
      &:hover {
        border-color: var(--n-500);

        // .input-option {
        //   border-left-color: var(--n-500);
        // }
      }
      &:focus-within {
        border-color: var(--b-400);

        // .input-option {
        //   border-left-color: var(--b-400);
        // }
      }
      &.warning {
        border-color: var(--o-500);
      }
      &.danger {
        border-color: var(--r-400);
      }
    }
    &.disabled {
      cursor: not-allowed;
      background-color: var(--n-100);
    }
  }

  // Label Left (deprecated)
  .label-left {
    padding: 12px 6px;
    &:first-child {
      padding-left: 16px;
    }
    &.small {
      &:first-child {
        padding-left: 12px;
      }
    }
  }

  // Input
  .input {
    min-width: 0;
    flex: 1;
    border: none;
    outline: 0;
    position: relative;
    background: transparent;
    padding: 12px 6px;
    box-sizing: border-box;
    transition: all 200ms ease;
    cursor: text;
    @include placeholder {
      color: var(--n-500);
      font-family: 'Inter', sans-serif;
      line-height: 150%;
      font-size: 16px;
    }
    &:first-child {
      padding-left: 16px;
    }
    &.disabled {
      cursor: not-allowed;
    }
    &.small {
      border-radius: 8px;
      padding: 8px 12px;
      @include placeholder {
        font-size: 14px;
      }
      &:first-child {
        padding-left: 12px;
      }
      &:last-child {
        padding-right: 12px;
      }
    }
  }

  // Loader
  .input-loader {
    padding: 12px 6px;
    &:last-child {
      padding-right: 16px;
    }
    &.small {
      &:last-child {
        padding-right: 12px;
      }
    }

    .loader {
      border-left-color: var(--b-400);
      border-right-color: var(--b-400);
      border-bottom-color: var(--b-400);
    }
  }

  // Trailing
  .input-trailing {
    padding: 12px 6px;
    &:last-child {
      padding-right: 16px;
    }
    &.small {
      &:last-child {
        padding-right: 12px;
      }
    }
  }

  // Icons
  .icon-left-wrapper,
  .icon-right-wrapper {
    display: flex;
    align-items: center;
    &.is-interactive {
      i {
        color: var(--b-800);
        cursor: pointer;
      }
    }
    i {
      color: var(--n-500);
      &.isax {
        font-size: 20px;
      }
      font-size: 12px;
    }
  }
  .icon-left-wrapper {
    padding: 12px 6px;
    &:first-child {
      padding-left: 16px;
    }
    &.small {
      padding: 8px 12px;
      &:first-child {
        padding-left: 12px;
      }
    }
  }
  .icon-right-wrapper {
    padding: 12px 6px;
    &:last-child {
      padding-right: 16px;
    }
    &.small {
      padding: 8px 12px;
      &:last-child {
        padding-right: 12px;
      }
    }
  }

  // Option
  .input-option {
    position: relative;
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 12px 6px;
    padding-left: 12px;
    align-self: stretch;
    cursor: pointer;
    border-left: 1px solid var(--n-100);
    transition: all 200ms ease;
    user-select: none;
    &:last-child {
      padding-right: 16px;
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
    }
    &.small {
      padding: 8px 12px;
      &:last-child {
        padding-right: 12px;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
    &.m-l-6 {
      margin-left: 6px;
    }

    i {
      font-size: 10px;
    }

    .option-items {
      position: absolute;
      background-color: #fff;
      border: 1px solid var(--n-300);
      border-radius: 12px;
      right: 0;
      z-index: 2;
      display: flex;
      flex-direction: column;
      transition: all 200ms ease;
      overflow: hidden;

      .input-option-item {
        padding: 12px;
        white-space: nowrap;
        min-width: 225px;
        &:hover {
          background-color: var(--b-100);
        }
        // &.selected {
        //   color: var(--b-400);
        // }
        &.small {
          padding: 8px;
        }
      }
    }
  }

  // Additional
  .input-additional {
    flex: 0 40%;
    align-self: stretch;
    overflow: hidden;
    display: flex;
    align-items: center;
    padding: 0 8px;
    color: var(--n-700);
    &.is-small {
      &:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
      padding-right: 12px;
    }
    &:last-child {
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
      padding-right: 16px;
    }
    &:not(.disabled) {
      background-color: var(--n-100);
    }

    .additional-overflow-wrapper {
      display: inline-block;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre;
    }
  }

  // Helper
  .input-helper {
    &:not(.disabled) {
      &.warning {
        color: var(--o-500);
      }
      &.danger {
        color: var(--r-400);
      }
    }
    &.disabled {
      color: var(--n-500);
    }
  }
}

.input-group {
  &.input-onboarding {
    .input.input {
      // Gives more priority over .input above
      box-shadow: inset 0 0 0 1px #b6b6b6, 0 0 0 4px transparent;
      background: #fff;
      border: none;
      font-weight: 400;
      padding: 12px;
      color: #000;

      &:hover {
        box-shadow: inset 0 0 0 3px rgba(18, 96, 235, 0.8),
          0 0 0 2px transparent;
        border: none;
      }
      &:focus {
        box-shadow: inset 0px 0px 0px 3px rgba(18, 96, 235, 0.4),
          0px 0px 0px 2px rgba(18, 96, 235, 0.1);
        border: none;
      }
    }
  }
}

/* Autocomplete */
.input-group {
  .autocomplete-results {
    width: 100%;
    position: absolute;
    left: 0;
    border: 1px solid #e5e5e5;
    padding: 4px;
    border-radius: 8px;
    box-sizing: border-box;
    z-index: 2;
    background-color: #fff;
    max-height: 240px;
    overflow-y: auto;

    .menu-item {
      padding: 8px;
      border-radius: 8px;
      overflow: hidden;
    }
  }
}
