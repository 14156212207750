@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;600&display=swap');

.jimer-attributes {
  .section-title {
    display: flex;
    align-items: center;

    a {
      color: #1260eb;
      font-size: 14px;
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 8px;
      margin-left: auto;

      i {
        font-size: 18px;
      }
    }
  }

  .jimer-attributes-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .search-wrapper {
      .input-group {
        height: 37px;

        .input-main-wrapper {
          border-radius: 8px;

          .label-left {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }

    .jimer-attributes-table {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .top {
        flex: 0 1 auto;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 8px;
      }

      .jimer-attributes-list {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .jimer-attribute {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          gap: 10px;
          align-self: center;
          width: 100%;

          .jimer-attribute-name {
            color: rgba(0, 0, 0, 0.4);
            font-family: Inconsolata;
            font-size: 14px;
            font-weight: 600;
            display: inline-flex;
            align-items: center;
            gap: 4px;
            padding: 4px 8px;
            border-radius: 8px;
            background-color: var(--n-100);
            width: fit-content;
            max-width: 100%;
            border: 1px solid var(--n-300);
            box-sizing: border-box;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            span {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .jimer-attribute-value {
            font-size: 14px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .jimer-attribute-source {
            font-size: 14px;
            display: flex;
            align-items: center;
            gap: 8px;

            .jimer-attribute-icon {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 26px;
              width: 26px;
              margin: auto 0;
              border-radius: 8px;
              border: 1px solid var(--n-300);

              &.segment {
              }
              &.jimo {
                color: #1260eb;
              }

              i {
                font-size: 16px;
                margin: auto;
              }
              img {
                height: 22px;
                width: 22px;
                margin: auto;
              }
            }
          }
        }
      }
    }
  }
}
