.s-settings-webhook-setup {
  .webhook-setup-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 34px;

    .left-wrapper {
      display: flex;
      align-items: center;
      gap: 24px;

      .btn {
        i {
          font-size: 14px;
        }
      }
    }

    .right-wrapper {
      display: flex;
      gap: 24px;

      a {
        color: var(--b-400);
        display: flex;
        align-items: center;
        gap: 8px;

        i {
          font-size: 18px;
        }
      }
    }
  }

  .webhook-loading {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 24px;
  }

  .card-webhook-details {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .input-name {
      .label-left {
        padding: 4px;
      }
    }

    .dropdown-trigger {
      cursor: pointer;
      margin: auto 0;

      .icon-wrapper {
        height: 40px;
        width: 40px;
        display: flex;
        border-radius: 12px;
        flex: 0 0 auto;
        border: 1px solid rgba(0, 0, 0, 0.1);
        align-items: center;
        justify-content: center;
        font-size: 24px;
      }
    }

    .input-description {
      textarea {
        height: 84px;
      }
    }

    .input-url {
      color: var(--n-700);
    }
  }

  .card-webhook-events {
    margin-top: 24px;

    .card-events-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .card-events-list {
      display: flex;
      flex-direction: column;
      padding-top: 24px;

      .events-header {
        display: grid;
        grid-template-columns: 60% 40%;
        margin-bottom: 16px;
      }

      .event-item {
        display: grid;
        grid-template-columns: 60% 35% 5%;
        align-items: center;
        &:not(:last-child) {
          margin-bottom: 16px;
        }

        .event-icon-name-wrapper {
          display: flex;
          align-items: center;
          gap: 16px;
        }

        .icon-wrapper {
          border: 1px solid var(--n-300);
          border-radius: 12px;
          padding: 8px;
          font-size: 18px;
        }

        .event-category {
          text-transform: capitalize;
        }

        .btn-event-delete {
          padding: 8px;
          margin-left: auto;
        }
      }
    }

    .title-wrapper {
      display: flex;
      flex-direction: column;
      gap: 4px;

      a {
        color: var(--b-400);
        text-decoration: underline;
        text-underline-offset: 3px;
      }
    }
  }
}
