.changelog-translation-manager-wrapper {
  background: #fff;
  display: flex;
  flex-direction: column;
  min-height: 0;
  flex: 1 1 auto;
  width: 100%;

  .changelog-translation-manager {
    display: flex;
    flex: 1 auto;
    flex-direction: column;
    gap: 16px;
    min-height: 0;
    padding: 12px 0;

    .changelog-translation-manager-header {
      display: flex;
      padding: 0 16px;

      .changelog-translation-manager-title {
        margin: auto 0;
        font-family: 'Montserrat';
        font-weight: 500;
        font-size: 16px;
      }

      i {
        cursor: pointer;
        margin: auto 0;
        margin-left: auto;
        color: #9598a9;
        padding: 4px;
      }
    }

    .loader-wrapper {
      display: flex;
      flex: 1 auto;
      flex-direction: column;
      align-items: center;

      .loader {
        margin: 40px 0 12px 0;
      }
    }

    .changelog-translation-manager-blocks {
      overflow-y: auto;
      padding: 0 16px;
      display: flex;
      flex-direction: column;
      gap: 16px;

      .translation-manager-block-title {
        margin-bottom: 8px;
      }

      .translation-manager-block-content {
        .markdown-editor {
          background: #fff;
          border-radius: 16px;
          border: 1px dashed #d9d9d9;

          .toolbar {
            border-radius: 16px 16px 0px 0px;
          }
        }
      }

      .input {
        border-radius: 16px;
      }

      .text-area {
        textarea {
          resize: none;
        }
      }

      .options,
      .slider-block-inputs {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }
  }
}
