.block-resource-center-action-cta {
  display: inline-flex;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  border: 1px solid transparent;
  white-space: nowrap;
  height: fit-content;
  justify-content: center;
  flex-basis: 0px;
  z-index: 11;

  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.6;
  }
  &.disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  .icon {
    display: flex;
    height: 100%;
    width: 100%;

    svg,
    img {
      margin: auto;
      width: 100%;
      height: 100%;
    }
  }
}

@-webkit-keyframes block-cta-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes block-cta-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes block-cta-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes block-cta-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
