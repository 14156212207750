.poke-list-wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1 auto;
  box-sizing: border-box;

  .poke-list {
    overflow-x: hidden !important;

    a:nth-last-child(1) {
      .push {
        &:after {
          border-bottom: none;
        }
      }
    }
  }

  &.layout-row,
  &.layout-row-large {
    .push {
      &:after {
        position: absolute;
        content: '';
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        width: 100%;
        bottom: 0px;
      }

      padding: 12px 0;
    }
  }
  &.layout-row-large {
    .push {
      min-height: 173px;
    }

    .audience-tags-wrapper {
      margin: auto 12px auto auto;

      & > div {
        margin-left: auto;
      }
    }
  }
  &.layout-row {
    .push {
      align-items: center;

      &:hover {
        .audience-tags-wrapper {
          // transition: all 300ms ease-in;
          filter: blur(2px);
          opacity: 0.3;
        }
      }

      .poke-status-tag-wrapper {
        position: unset;
        width: 76px;
        text-align: center;
      }

      .infos {
        display: flex;

        .push-title-wrapper {
          margin-right: 12px;
          margin-bottom: 0px;
        }
      }
      .actions {
        top: 28px;
      }
    }
  }
  &.layout-grid {
    .infos {
      margin: unset !important;
      padding: 0 8px;
    }
    .push {
      flex-direction: column;
      padding-bottom: 20px;
      min-height: 173px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 16px;
      padding: 12px;
      gap: 16px;
      background-color: var(--white);

      &:hover {
        box-shadow: 0px 12px 12px 0px rgba(0, 0, 0, 0.05);
      }

      .preview {
        flex: 1 auto;
        width: 100%;
      }
    }
    .actions {
      flex-direction: column;
    }
  }

  .push {
    position: relative;
    display: flex;
    cursor: pointer;

    box-sizing: border-box;

    &:hover {
      // animate background-color and box-shadow
      transition: all 300ms ease-in;

      .preview {
        transition: all 300ms ease-in;
        background: #b5bfd5;
        box-shadow: 0px 14px 8px rgba(0, 0, 0, 0.05);
      }
      .push-title {
        transition: all 300ms ease-in;
        color: #1260eb !important;
      }
    }

    .loader {
      margin: auto;
    }

    .tag-view {
      padding: 8px;
      border-radius: 6px;
      font-size: 16px;
      font-weight: 500;
    }

    .preview {
      display: flex;
      width: 310px;
      position: relative;
      border-radius: 12px;
      background: #e6e6e6;
      transition: all 100ms ease-in;
      transform: scale(1);
      overflow: hidden;

      .empty-steps {
        margin: auto;
      }

      .poke-preview-icon-wrapper {
        padding: 8px;
        position: absolute;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 8px;
        right: 8px;
        bottom: 8px;
        color: #fff;
        display: none;
      }

      .live-preview {
        position: absolute;
        margin: auto;
        transform: scale(0.8);
        width: 100%;
        height: 100%;
      }

      .deprecated-wrapper {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 8px;
        background: rgba(0, 0, 0, 0.68);
        display: flex;
        padding: 6px 8px;
        gap: 4px;
        margin: 8px;
        z-index: 1;

        .deprecated-label {
          margin: auto;
          color: #fff;
          font-size: 16px;
          font-family: Inter;
          font-weight: 500;
        }
      }
    }

    .infos {
      margin: auto;
      margin-left: 24px;

      .push-title-wrapper {
        display: flex;
        flex-direction: column;
        gap: 4px;
        margin-bottom: 12px;

        .push-title {
          align-items: center;
          font-weight: 500;
          color: #000000;
          font-size: 20px;
          display: flex;
          gap: 8px;
          overflow-wrap: anywhere;

          .boosted-icon-wrapper {
            box-sizing: border-box;
            display: flex;
            width: 24px;
            height: 24px;
            padding: 4px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 8px;
            background: #f5f2fe;

            i {
              font-size: 16px;
              color: #bb68ff;
            }
          }
        }

        .created-at {
          color: rgba(0, 0, 0, 0.5);
          font-size: 14px;
        }
      }

      .poke-details-wrapper {
        display: flex;
        &.in-grid-layout {
          margin: 16px 0;
        }
        &.in-row {
          margin-right: 12px;
        }
        &.in-row-large {
          margin-top: 12px;
        }

        .tag-icon {
          display: flex;
          border-radius: 8px;
          border: 1px solid rgba(0, 0, 0, 0);
          padding: 8px;
          align-items: center;

          &.discovery {
            background-color: #e6f5fd;
          }
          &.adoption {
            background-color: #e6fde8;
          }

          i,
          img {
            margin: auto;
            margin-right: 8px;
            font-size: 14px;
          }

          span {
            font-size: 16px;
          }
        }

        .poke-type-wrapper {
          display: flex;
          flex-wrap: wrap;
          gap: 8px;

          .type-tag {
            display: flex;
            border-radius: 8px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            padding: 8px;
            font-size: 16px;

            i {
              margin: auto;
              margin-right: 8px;
              font-size: 14px;
            }
          }
        }
      }
    }

    .audience-tags-wrapper {
      display: flex;
      gap: 8px;
      flex-flow: wrap;

      .poke-stats-wrapper {
        display: flex;
        gap: 4px;
        align-items: center;
        white-space: wrap;
      }
      .tag-view {
        margin-right: 12px;
        margin-left: 0px;
      }
    }

    .audience,
    .poke-environments {
      .poke-stats-wrapper,
      .poke-environments-content {
        display: flex;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 16px;
        padding: 4px 8px;

        .audience-string {
          flex: 1;
        }
        i {
          margin-right: 4px;
          display: flex;
          align-items: center;
          font-size: 16px;
        }
      }
    }

    .actions {
      display: none;
      margin: auto;
      transition: all 400ms ease-in-out 0s;
      position: absolute;
      right: 8px;
      top: 8px;

      .menu-poke-top {
        display: flex;
        justify-content: flex-end;
      }
      .menu-poke-actions {
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        &.is-hidden {
          display: none;
        }
        .btn {
          align-self: flex-end;
          margin-right: 0 !important;
          margin-bottom: 12px;
        }
      }
      .btn {
        align-self: center;
        margin-right: 8px;
        &:nth-last-child(1) {
          margin-right: 0;
        }
      }
    }

    &:hover {
      .actions,
      .poke-preview-icon-wrapper {
        display: flex;
      }
    }
  }

  .load-more-wrapper {
    display: flex;
    justify-content: center;
    margin: 24px auto;
  }
}

.poke-card-type {
  display: flex;
  align-items: center;
  gap: 8px;

  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 6px;

    i {
      font-size: 12px;
    }
  }
}

.modal-optional-delete {
  padding: 0;

  .modal-title {
    padding: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 0;
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 24px;
  }

  .content {
    padding: 24px;
    font-weight: 400;
    font-size: 18px;
  }

  .action-btns {
    display: flex;
    padding: 24px;
    margin-top: 0;
    gap: 8px;
    justify-content: flex-end;
  }
}

.modal-preview-poke {
  display: flex;
  flex-direction: column;
  padding: 0;
  height: 80%;
  width: 70%;

  @media (max-height: 900px) {
    height: 95%;
  }
  @media (max-width: 1200px) {
    width: 90%;
  }

  .modal-title {
    display: flex;
    padding: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-weight: 500;
    font-size: 24px;
    font-family: 'Montserrat';
    margin-bottom: 0;
    flex-shrink: 0;
    min-height: 0;

    .text {
      margin: auto 0;
    }

    .close-btn {
      background-color: #f4f4f4;
      height: 40px;
      width: 40px;
      border-radius: 12px;
      padding: 0;
      margin-right: 16px;
      margin-left: auto;
      display: flex;

      &:hover {
        background-color: #dddddd;
      }

      i {
        margin: auto !important;
        color: #000000;
      }
    }
  }

  .preview-group {
    border-bottom-right-radius: 8px;
  }
  .steps-list-wrapper {
    border-bottom-left-radius: 8px;
  }
}

.tooltip-boosted-pushes-table-content {
  background: #071331;
  padding: 8px;
  color: #fff;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
}

.poke-status-tag-wrapper {
  z-index: 2;
  position: absolute;
  left: 8px;
  top: 8px;

  .status-tag {
    padding: 10px 14px;
    border-radius: 8px;

    &.tag-live {
      background: #dffee1;
      color: #167322;
    }

    &.tag-paused {
      background: #fdefe6;
      color: #ffa15e;
    }

    &.tag-draft {
      background: #eeeeee;
      color: #1e1e1e;
    }

    &.tag-expired {
      background: #fefefe;
      color: #c0c0c0;
    }

    &.tag-scheduled {
      background: #dae7ff;
      color: #1260eb;
    }
  }
}
