.block-resource-center-trigger-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 500ms ease-in-out;

  .icon {
    display: flex;
    height: 100%;
    width: 100%;

    svg,
    img {
      margin: auto;
      width: 100%;
      height: 100%;
    }
  }
}
