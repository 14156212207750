.block-settings.resource-center-action-cta {
  .section-title-extra {
    .block-group {
      .icon-wrapper {
        background-color: var(--g-100);

        i {
          font-size: 10px;
          color: var(--n-800);
        }
      }
    }
  }

  .action-picker {
    .actions-wrapper {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    .add-action-element-placeholder-btn {
      .label-left {
        .action-icon-wrapper {
          display: flex;
          width: 32px;
          height: 32px;
          box-sizing: border-box;
          border: 1px solid var(--n-100);
          box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.04);
          border-radius: 12px;
          background: var(--n-100);

          i {
            margin: auto;
            font-size: 18px;
            box-sizing: border-box;
            border-radius: 12px;
            text-align: center;
            color: var(--n-600);
          }
        }
      }
    }
  }

  .item-icon-picker {
    .preview-icon {
      display: flex;
      width: 32px;
      height: 32px;
      box-sizing: border-box;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.04);
      border-radius: 12px;

      svg {
        height: 24px;
        width: 24px;
        margin: auto;
      }
      img {
        margin: auto;
        width: 24px;
        height: 24px;
      }
    }
  }
}

.add-action-element-button-content {
  min-width: 158px;
  background: #ffffff;
  box-shadow: 0px 14px 19px rgb(0 0 0 / 3%);
  border-radius: 8px;
  margin: 4px auto;
  z-index: 1002 !important;

  .actions-list {
    display: flex;
    flex-direction: column;
    padding: 4px;
    overflow-y: auto;

    .divider {
      margin: 4px;
      width: calc(100% - 8px);
    }

    .dropdown-menu-item-wrapper {
      &.disabled {
        opacity: 0.4;
        cursor: not-allowed;
        .dropdown-menu-item {
          pointer-events: none;
        }
      }

      .dropdown-menu-item {
        display: flex;
        gap: 12px;
        align-items: center;
        padding: 12px;
        border-radius: 8px;
        font-family: 'Montserrat';
        color: #000;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;

        &:hover {
          background-color: #1260eb0f;
        }

        .icon-wrapper {
          display: flex;
          height: 32px;
          width: 32px;
          border-radius: 8px;

          &.go-to-step {
            background-color: var(--g-200);
          }

          &.dismiss {
            background-color: var(--r-200);
          }

          &.launch-experience {
            background-color: var(--b-150);
          }

          &.navigate-to {
            background-color: var(--n-200);
          }

          &.run-js-code {
            background-color: var(--y-300);
          }

          &.open-post {
            background-color: var(--b-150);
          }

          &.book-interview {
            background-color: var(--o-200);
          }
          &.snooze {
            background-color: var(--p-200);
          }

          i {
            margin: auto;
            font-size: 16px;

            &.isax {
              font-size: 20px;
            }
          }
        }

        .subtitle {
          display: flex;
          gap: 4px;
          align-items: center;
        }
      }
    }
  }
}
