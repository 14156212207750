.s-space {
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: column;
  overflow: hidden;
  background: #f7f9fe;

  &.is-loading {
    display: flex;
    justify-content: center;

    .space-content {
      height: calc(100% - 144px);

      .loader-wrapper {
        margin: auto;
      }
    }
  }
  &.is-empty {
    display: flex;
    align-items: center;
    justify-content: center;

    .main {
      flex: 0 0 235px;
      display: flex;
      flex-flow: column;
      align-items: center;
      text-align: center;
      font-size: 24px;
      font-weight: 500;
    }
  }

  .space-content-wrapper {
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex: 1 auto;

    .space-content {
      display: flex;
      flex-flow: column;
      gap: 24px;
      padding-top: 24px;
      overflow-y: auto;
      overflow-x: hidden;
      flex: 1 auto;
      margin: auto;
      margin-top: 0;
      overflow: visible;
      height: 100%;
      box-sizing: border-box;
      min-width: 0;

      .space-info-section,
      .pushes-view-editor {
        margin: 0 72px;
      }

      .pushes-view-editor {
        padding: 0;
      }
    }
  }
}
