.pokes-header {
  display: flex;
  padding: 32px;
  padding-bottom: 16px;
  align-items: center;
  gap: 24px;
  align-self: stretch;

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;

    .title {
      color: #000;
      font-family: Montserrat;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 38.4px */
      letter-spacing: -0.5px;
    }

    .subtitle {
      color: var(--content-tertiary, #9ca1ad);
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 24px */
    }
  }

  .new-push-btn {
    margin-left: auto;
  }
}
