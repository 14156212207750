.modal-add-experience-sidebar {
  display: flex;
  width: 208px;
  box-sizing: border-box;
  flex-direction: column;
  align-items: flex-start;
  flex: 0 0 auto;
  align-self: stretch;
  justify-content: space-between;
  overflow: hidden;
  overflow-y: auto;
  border-right: 1px solid var(--n-300);

  .menu {
    padding: 24px 12px;
    box-sizing: border-box;
    width: 208px;
  }
  .menu-item {
    padding: 8px 12px;
    margin-bottom: 2px;
    border-radius: 12px;
    &:not(.is-active):hover {
      background-color: rgba(240, 242, 244, 1) !important;
      color: rgba(77, 99, 123, 1) !important;
    }
    &.is-active:hover {
      background-color: rgba(240, 242, 244, 1) !important;
      color: #000 !important;
    }
    &.is-active {
      background-color: rgba(240, 242, 244, 1);
      color: #000;
    }

    i {
      font-size: 20px;
    }
  }

  .modal-add-experience-sidebar-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
  }
}
