.poke-builder {
  height: 100%;
  position: relative;
  display: grid;
  flex: 1 auto;
  transition: all 300ms ease-in-out; // commented out to avoid not centered issue

  &.grid-a-b {
    grid-template-columns: 25% 75%;
  }
  &.grid-a-b-a {
    grid-template-columns: 25% 50% 25%;

    &.mode-triggers {
      grid-template-columns: 30% 75% 0;
    }
  }

  .block-manager-wrapper,
  .navigator-wrapper,
  .theme-manager-wrapper,
  .translation-manager-wrapper,
  .trigger-manager-wrapper,
  .logic-manager-wrapper {
    box-shadow: 10px 4px 10px 0 #0000000f;
    min-width: 0;

    .block-manager,
    .theme-manager,
    .translation-manager,
    .logic-manager {
      max-width: 100%;
    }
  }

  &:not(.is-logic-view) {
    .block-manager-wrapper,
    .navigator-wrapper,
    .theme-manager-wrapper,
    .translation-manager-wrapper,
    .trigger-manager-wrapper,
    .logic-manager-wrapper {
      border-right: 1px solid #0000001a;
    }
  }

  &.is-logic-view {
    display: flex;

    .poke-render-wrapper {
      height: 100%;
      width: 100%;
    }

    .poke-builder-left-wrapper,
    .poke-builder-right-wrapper {
      position: absolute;
      display: flex;

      & > div {
        border: 1px solid #0000001a;
      }
    }

    .poke-builder-left-wrapper {
      left: 0;
      min-width: 380px;
      max-width: 500px;
      width: 30%;
      height: 100%;
      z-index: 1;

      &.no-mode {
        width: unset;
        min-width: unset;

        .poke-builder-sidebar {
          border-right: 0;
        }
      }

      & > div {
        min-height: 0;
      }

      &.hide-in-left {
        animation: fade-out-left 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
        pointer-events: none;
      }

      .poke-builder-sidebar-wrapper {
        border-radius: 8px;
        margin: 8px;
        height: unset;
      }
    }

    .poke-builder-right-wrapper {
      right: 0;
      min-width: 320px;
      max-width: 366px;
      width: 25%;
      height: 100%;
      z-index: 1;

      &.mode-triggers {
        width: 0;
      }

      &.show-in-right {
        animation: fade-in-right 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
      }

      &.hide-in-right {
        animation: fade-out-right 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
        pointer-events: none;
      }

      .poke-block-editor-wrapper {
        margin: 8px;
        border-radius: 8px;
        height: calc(100% - 16px);
      }
    }
  }

  @media screen and (min-width: 1600px) {
    &.grid-a-b {
      grid-template-columns: 450px auto 350px;
    }
    &.grid-a-b-a {
      grid-template-columns: 450px auto 350px;

      &.mode-triggers {
        grid-template-columns: 550px auto 0;
      }

      .poke-block-editor-wrapper {
        width: 350px;
      }
    }

    &.is-logic-view {
      &.mode-triggers {
        .poke-builder-left-wrapper {
          width: 550px;
        }
      }

      .poke-builder-left-wrapper {
        width: 450px;
      }

      .poke-builder-right-wrapper {
        width: 366px;
      }
    }
  }

  .poke-builder-left-wrapper {
    display: flex;
    min-height: 0;
  }

  .poke-render-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &.poke-is-banner {
      align-items: flex-start;
      justify-content: unset;
    }
  }

  .poke-builder-right-wrapper {
    min-height: 0;
  }
}

.preview-selector-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .preview-selector-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #00000033;
    z-index: 999;
  }

  .preview-selector {
    width: 320px;
    background: white;
    box-shadow: 0px 12px 38px 0px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    border: 1px solid var(--n-100);
    z-index: 1000;
  }
}
