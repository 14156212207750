.experience-drawer {
  overflow: visible;
  margin: 8px;
  right: 0 !important;
  top: 0 !important;
  left: unset !important;
  position: absolute;
  height: calc(100% - 16px);
  width: 996px;
  max-width: calc(100% - 100px);
  display: flex;
  flex-direction: column;
  padding: 0;
  border-radius: 12px;

  .s-poke {
    border-radius: 12px;

    .poke-header {
      .infos {
        .back-btn {
          display: none;
        }
      }

      .actions {
        margin: auto;
      }
    }

    .poke-content-wrapper {
      .poke-content {
        padding: 24px;
        gap: 24px;
        min-width: 0;
      }
    }
  }

  .experience-drawer-detached-actions {
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: absolute;
    top: 0;
    left: -56px;

    .btn {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      background-color: var(--white);
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.2s ease-in-out;

      &:hover {
        background-color: var(--n-200);
      }

      i {
        font-size: 14px;

        &.isax {
          font-size: 20px;
        }
      }
    }
  }
}

.experience-drawer-overlay {
  z-index: 998;
}
