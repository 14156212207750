.block-settings.changelog-trigger {
  .changelog-trigger-show {
    .select-group-wrapper {
      .jimo-select__single-value {
        span:last-child {
          display: none;
        }
      }
    }
  }
  .preview-shadow-color {
    margin: auto 4px;
    width: 32px;
    height: 32px;
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
    flex-shrink: 0;
  }

  .background-picker {
    .preview-background-color {
      margin: auto 4px;
      width: 32px;
      height: 32px;
      box-sizing: border-box;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.04);
      border-radius: 12px;
    }
  }

  .item-icon-picker {
    .preview-icon {
      display: flex;
      margin: auto 4px;
      width: 32px;
      height: 32px;
      box-sizing: border-box;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.04);
      border-radius: 12px;
      flex-shrink: 0;

      svg {
        height: 24px;
        width: 24px;
        margin: auto;
      }
      img {
        margin: auto;
        width: 24px;
        height: 24px;
      }
    }
  }

  .change-trigger {
    .jimo-select {
      &__single-value {
        display: flex;
      }
    }
    .i {
      margin-right: 12px;
    }
    .offset-input-wrapper {
      display: flex;
      margin-top: 12px;

      .inner-labeled-input {
        &:first-child {
          margin-right: 24px;
        }

        .input-group {
          width: 80px;
        }
      }
    }
    .minimizing-side-badge {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 12px;
    }
    .extra-disable-notif-badge {
      display: flex;
      margin-top: 12px;
      justify-content: space-between;
      align-items: center;
    }
  }

  .trigger-title-content-input {
    textarea {
      height: 74px !important;
    }
  }

  .code-snippet {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;

    .copy-row {
      display: flex;
      height: 36px;
      justify-content: space-between;
      display: flex;
      align-items: center;
      align-self: stretch;

      .title {
        display: flex;
        align-items: center;
        gap: 8px;

        i {
          cursor: pointer;
        }
      }
    }

    .code-row {
      width: 100%;

      pre {
        border-radius: 8px;
        padding: 12px 16px !important;
        margin: 0;
        display: flex !important;

        code {
          background-color: unset;
          color: unset;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .custom-trigger {
    display: flex;
    gap: 8px;
    align-items: center;
    cursor: pointer;
  }
}

.changelog-trigger-select {
  .isax {
    font-size: 16px;
    margin-right: 10px;
    margin-left: 2px;
  }
  i:not(.isax) {
    margin-right: 8px;
    padding: 0 2px;
  }
  .trigger-pulsating {
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: var(--r-400);
    color: #fff;
    font-size: 10px;
    margin-right: 10px;
    margin-left: 4px;
    animation: trigger-animation-pulsating 2s ease-in-out both infinite;
  }
}
.changelog-trigger-select.jimo-select__single-value {
  display: flex;
  align-items: center;
}

.changelog-trigger-show-select {
  .jimo-select__menu {
    right: 0;

    .jimo-select__menu-list {
      max-width: 250px;

      .jimo-select__option {
        display: flex;
        flex-direction: column;
        gap: 2px;
        align-items: flex-start;
      }
    }
  }
}

@keyframes trigger-animation-pulsating {
  0% {
    box-shadow: 0px 0px 0px 0px #ff454569;
  }
  100% {
    box-shadow: 0px 0px 0px 6px #ff454500;
  }
}
