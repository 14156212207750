.clickable-block-overlay {
  z-index: 10;
  position: absolute;
  left: 4px;
  right: 4px;
  top: 4px;
  bottom: 4px;
  border: 1px solid #000000;
  border-radius: 0;
  opacity: 0;
  box-sizing: content-box;
  transition: all 0.2s ease-in-out;
  pointer-events: none;

  &.is-not-card-elem {
    pointer-events: none;
  }

  :hover:not(:has(.poke-block-clickable:hover)) > &,
  .selected > & {
    opacity: 1;
    left: -4px;
    right: -4px;
    top: -4px;
    bottom: -4px;

    &.without-overflow {
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }

    &.without-bottom-overflow {
      bottom: 0;
    }
  }

  .selected > &.is-not-card-elem {
    background: transparent !important;
  }
}
