.block-resource-center-minimize {
  cursor: pointer;
  height: 40px;
  width: 40px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;

  i {
    font-size: 14px;
  }
}
