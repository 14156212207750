.space-header {
  display: flex;
  gap: 24px;
  align-items: center;
  position: relative;
  flex: 0 1 auto;
  padding: 20px 24px;
  transition: all 0.2s ease-in-out;
  background-color: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  .infos {
    flex: 1 1 auto;
    font-size: 20px;
    font-weight: 500;
    display: flex;
    align-items: center;
    min-width: 0;

    .back-btn {
      background-color: #f4f4f4;
      height: 40px;
      width: 40px;
      border-radius: 12px;
      padding: 0;
      margin-right: 16px;
      flex-shrink: 0;

      &:hover {
        background-color: #dddddd;
      }

      i {
        margin: auto !important;
        color: #000000;
      }
    }

    .icon-wrapper {
      padding: 12px;
      display: flex;
      border-radius: 12px;
      margin-right: 16px;

      i {
        margin: auto;
        font-size: 24px;
      }
    }

    .space-title-wrapper {
      display: flex;
      flex-direction: column;
      gap: 4px;
      min-width: 0;

      .space-title {
        font-size: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .space-description {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .space-actions {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-left: auto;
    flex-shrink: 0;
  }
}
