.spaces-header {
  display: flex;
  flex: 0 1 auto;
  box-sizing: border-box;
  padding: 32px 26px;
  flex: 0 0 50px;
  align-items: center;
  border-bottom: 1px solid var(--n-300);
  background-color: var(--white);

  .actions-wrapper {
    margin: auto 0;
    margin-left: auto;
    display: flex;
    gap: 12px;

    .divider {
      height: 42px;
      margin: auto;
    }

    .btns-wrapper {
      display: flex;
      gap: 12px;

      .btn {
        height: max-content;
      }
    }
  }
}
