.tracker-list-users-drawer {
  overflow: hidden;
  margin: 8px;
  right: 0 !important;
  top: 0 !important;
  left: unset !important;
  position: absolute;
  height: calc(100% - 16px);
  width: 756px !important;
  display: flex;
  flex-direction: column;
  padding: 0;
  border-radius: 12px;

  .modal-title {
    padding: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-weight: 500;
    font-size: 24px;
    font-family: 'Montserrat';
    margin-bottom: 0;
    min-height: 0;
    flex: 0 0 auto;
  }

  .content-wrapper {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding: 24px;
    background: #f8f8f8;
    gap: 12px;

    .radio-group {
      flex: 0;
    }

    .users-filter {
      margin-left: auto;
      display: flex;
      gap: 8px;
      align-items: center;
      cursor: pointer;
      color: #000;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 24px */
    }

    .poke-segment-users-wrapper {
      overflow: hidden;
      display: flex;
      box-sizing: border-box;
      position: relative;
      flex: 1 auto;
      border-radius: 12px;
      background: #fff;
      box-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.05);

      .poke-segment-users-content {
        width: 100%;
        display: flex;
        flex-direction: column;

        & > div {
          padding: 0 24px;
        }

        .loader-wrapper {
          margin: auto;
        }

        .users-header {
          display: flex;
          padding: 12px 16px;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;
          border-bottom: 1px solid var(--border-minimal, #e5e5e5);

          .count-users {
            display: flex;
            padding: 8px 12px;
            align-items: center;
            gap: 8px;
            font-family: 'Montserrat';
            font-weight: 600;
            font-size: 18px;

            i {
              margin-right: 8px;
            }
          }

          .users-actions {
            display: flex;
            gap: 8px;
          }
        }

        .users-list {
          min-height: 0;
          height: 100%;
          display: flex;
          flex-direction: column;

          .top {
            flex: 0 1 auto;
            display: grid;
            grid-template-columns: 10% 30% 30% 30%;
            padding: 16px 12px 12px 12px;
            box-sizing: border-box;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            text-transform: uppercase;
            color: rgba(0, 0, 0, 0.4);
          }

          .loader-wrapper {
            display: flex;
            justify-content: center;
            padding-top: 24px;
          }
          .list {
            flex: 1 0 auto;
            overflow: auto;
            flex: 1 1 100%;
            box-sizing: border-box;
            padding-bottom: 72px;

            .card-user {
              border-radius: 12px;
              padding: 12px;
              display: grid;
              grid-template-columns: 10% 30% 30% 30%;
              align-items: center;
              transition: all 300ms ease 0s;
              cursor: pointer;

              &.completed {
                background: linear-gradient(
                  270deg,
                  rgba(235, 255, 237, 0) -6.67%,
                  #ebffed 106.49%
                );

                .username-wrapper {
                  .username {
                    color: #167322;
                  }
                }

                .user-last-activity {
                  .content {
                    .step-name {
                      color: var(--content-accent-positive, #167322);
                    }
                  }
                }
              }

              &:hover {
                background: #f3f7fe;

                .avatar {
                  scale: 1.25;
                }

                .username-wrapper {
                  .username {
                    color: #1260eb;
                  }
                }
                .user-tags {
                  .tags-list-wrapper {
                    border-color: rgba(0, 0, 0, 0.1);
                  }
                  .icon-chevron-bottom {
                    color: #b6b6b6;
                  }
                }
                .user-actions {
                  opacity: 1;
                }
              }

              &:not(:last-child) {
                margin-bottom: 16px;
              }

              .user-avatar-wrapper {
                .avatar {
                  height: 40px;
                  width: 40px;
                  transition: all 300ms ease-in-out 0s;
                }
              }

              .username-wrapper {
                display: flex;
                flex-direction: column;

                .username {
                  text-align: left;
                  display: flex;
                  flex-direction: column;
                  overflow: hidden;
                  color: #000;
                  font-family: Inter;
                  font-size: 16px;
                  font-weight: 500;
                }

                .anonymous-name {
                  color: rgba(0, 0, 0, 0.5);
                  font-family: Inter;
                  font-size: 14px;
                  font-weight: 400;
                }
              }
              .user-last-activity {
                display: flex;
                align-items: center;
                gap: 12px;

                &.completed {
                  .content {
                    .step-name {
                      color: var(--content-accent-positive, #167322);
                    }
                  }
                }

                .circular-progress-wrapper {
                  height: 24px;
                  width: 24px;
                  flex: 0 0 auto;
                }

                .content {
                  display: flex;
                  flex-direction: column;
                  gap: 2px;

                  .step-name {
                    color: #000;
                    font-family: Inter;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                  }

                  .event-date {
                    color: rgba(0, 0, 0, 0.5);
                    font-family: Inter;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                  }
                }

                .icon-wrapper {
                  i {
                    font-size: 18px;
                  }
                }
              }
              .user-tags {
                display: flex;

                .tags-list-wrapper {
                  border-color: transparent;
                  transition: all 300ms ease 0s;
                }
                .icon-chevron-bottom {
                  color: transparent;
                  transition: all 300ms ease 0s;
                }
              }
              .user-activity {
                display: flex;
                flex-direction: column;
              }
              .user-btn-profile {
                display: flex;
                justify-content: center;
              }
              .icon-close {
                opacity: 0.1;
              }
              .user-actions {
                opacity: 0;
              }
            }
          }
        }
      }
    }
  }
}

.tooltip-stuck-users-content {
  border-radius: 12px;
  background: var(--surface-primary-inverted, #071331);
  display: flex;
  width: 239px;
  padding: 12px;
  align-items: center;
  gap: 12px;
  color: #fff;
}
