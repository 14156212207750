@import './Variables';

.jimo-poke-wrapper {
  height: 100%;
  position: relative;

  .jimo-poke {
    position: relative;
    overflow: hidden; // keep it for gradient
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    max-width: 100%;
    max-height: 100%;
    // &:not(.has-jimo-label, .is-poke-banner) {
    //   padding-bottom: 24px;
    // }
    &.is-poke-banner {
      &.has-custom-height {
        padding: 0;
        margin: auto;
      }
    }
    &.in-concept {
      background-color: transparent !important;
      height: 100%;
    }
    &.has-animations-disabled {
      *:not(.bypass-animation-disabled) {
        animation: none !important;
      }
    }
    &.has-custom-height {
      .card-layout-wrapper {
        flex-grow: 1;
        flex-shrink: 1;
      }
    }
    &.is-hint {
      .poke-block-user {
        margin: 0;
      }

      .poke-block-step {
        gap: 8px;

        .poke-block-label,
        .poke-block-title,
        .poke-block-body {
          margin: 0;
        }

        .poke-block-body {
          line-height: 150%;
        }
      }

      .poke-layout-bottom {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }

      .poke-block-cta {
        margin-bottom: 0;

        .cta-left,
        .cta-center,
        .cta-right {
          padding: 0;
        }
      }

      .jimo-label-wrapper {
        height: unset;
        justify-content: flex-start;
        padding: 8px 16px;
        padding-top: 0;

        .poke-renderer-poke-label {
          flex: unset;
          background: transparent !important;

          .poke-label-content {
            background: transparent !important;
            padding: 0;
          }
        }

        .poke-jimo-label {
          flex: unset;
          background: transparent !important;

          .poke-label-content {
            background: transparent !important;
            padding: 0;
          }
        }
      }
    }

    .card-layout-wrapper {
      min-height: 0;
      overflow: hidden;
      display: flex;
      flex-direction: column;
    }

    .jimo-label-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 34px; // CAUTION : if this value change, please update JIMO_LABEL_HEIGHT in "poke/index.js" with the same value
      flex-shrink: 0;
      margin-top: auto;
    }

    .poke-block-clickable {
      cursor: pointer;
      position: relative;

      // &:not(.poke-block-media, .poke-block-opinion) {
      //   width: fit-content;
      // }
    }
  }

  .vertical-layout-wrapper {
    display: flex;
    height: 100%;
    min-height: 0;

    .media-wrapper {
      display: flex;
      flex: 1 1 0px;

      .poke-block-media {
        display: flex;
        height: 100%;
        width: 100%;
      }
    }

    .content-wrapper {
      display: flex;
      flex-direction: column;
      flex: 1 1 0px;
    }
  }

  .horizontal-layout-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 0;
  }

  .jimo-label-wrapper {
    animation: block-jimo-label-in $animationBlockInDuration ease-in-out
      $animationBlockInDelay both;

    &.is-animating-out {
      animation: block-jimo-label-out $animationBlockOutDuration ease-in-out
        both;
    }
  }

  .poke-layout-scrollable-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 0;
    margin: -8px;
    padding: 8px;

    .poke-layout-scrollable {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      min-height: 0;
      overflow-y: auto;
      overflow-x: hidden;
    }

    .poke-layout-top,
    .poke-layout-middle {
      flex: unset;
      flex-shrink: 0;
      min-height: 0;
    }

    .poke-layout-bottom {
      flex: unset;
      flex-shrink: 0;
    }
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background: #0000001a;
    border-radius: 6px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 6px;
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.3);
  }
}

@-webkit-keyframes block-jimo-label-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes block-jimo-label-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes block-jimo-label-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes block-jimo-label-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
