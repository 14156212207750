.lightweight-poke-builder-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 19;

  .lightweight-poke-builder {
    height: calc(100% - 80px);
    box-shadow: 0px 9px 16px rgb(12 23 37 / 16%);
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    width: 380px;
    padding: 0;
    padding-top: 0;
    background: var(--white);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-y: auto;

    .lightweight-poke-builder-header {
      margin: 8px;
      display: flex;
      height: 48px;

      .poke-builder-sidebar-wrapper {
        height: auto;
        overflow: visible;

        .poke-builder-sidebar {
          flex-direction: row;
          width: auto;
          border-right: 0;

          padding: 0;
        }
      }

      .handle {
        cursor: move;
        border-radius: 12px;
        display: flex;
        align-items: center;
        margin: auto 4px;
        margin-left: auto;

        .icon-drag {
          margin-left: auto;
          background: rgba(214, 214, 214, 0.2);
          border-radius: 8px;
          height: 28px;
          width: 28px;
          display: flex;

          i {
            margin: auto;
            font-size: 16px;
          }
        }
      }
    }

    .translation-manager,
    .logic-manager,
    .theme-manager,
    .block-manager {
      width: auto;
    }
  }
}
