.integrations-subnav {
  margin-top: 0;

  .tab-list {
    padding-left: 0;

    .tab-list-item {
      &:hover {
        .label-wrapper {
          background: transparent !important;
        }
      }
    }
    .tab-list-active {
      .label-wrapper {
        background: transparent !important;
      }
    }
  }
}
