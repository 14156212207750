.block-resource-center-embedded-checklist {
  display: flex;
  flex-direction: column;

  &.in-builder {
    .checklist {
      pointer-events: none;
      transition: none;
    }
  }
}
