.audience-header {
  display: flex;
  align-items: center;
  position: relative;
  flex: 0 1 auto;
  padding: 20px 24px;
  transition: all 0.2s ease-in-out;
  border-bottom: 1px solid rgba(0, 0, 0, 0);

  &.scrolled {
    background-color: #ffffff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.06);
  }

  .infos {
    flex: 1 1 auto;
    font-size: 20px;
    font-weight: 500;
    display: flex;
    align-items: center;

    .back-btn {
      background-color: #f4f4f4;
      height: 40px;
      width: 40px;
      border-radius: 12px;
      padding: 0;
      margin-right: 16px;

      &:hover {
        background-color: #dddddd;
      }

      i {
        margin: auto !important;
        color: #000000;
      }
    }

    .icon-wrapper {
      padding: 12px;
      display: flex;
      border-radius: 12px;
      margin-right: 16px;

      &.adoption {
        background-color: #c4e9bf;
      }
      &.discovery {
        background-color: #bcddff;
      }
      &.is-checklist {
        background-color: var(--y-300);
      }
      &.is-changelog {
        background-color: #bcddff;
      }
      &.is-resource-center {
        background-color: var(--p-200);
      }

      i,
      img {
        margin: auto;
        font-size: 24px;
      }
    }

    .push-title-wrapper {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .push-title {
        font-size: 20px;
      }
    }

    .poke-tag-selector {
      margin-left: 12px;
    }
  }
  .actions {
    display: flex;
    margin-bottom: auto;
    gap: 16px;
  }

  .btn {
    height: 38px;
  }
}

.tooltip-poke-post-publish-content {
  z-index: 10000000000;
  background: #071331;
  opacity: 0.8;
  padding: 8px;
  color: #fff;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  width: 240px;

  & > div:not(.tooltip-children) {
    color: #071331 !important;
  }
}

.troubleshooting-to-edit {
  background: #fff7f5;
  box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 200px;
  min-height: 0;
  padding: 12px;
  cursor: pointer;
}
