.modal-webhook-events {
  width: 600px;
  height: 80%;
  display: flex;
  flex-direction: column;

  .modal-header {
    padding: 16px;
    border-bottom: 1px solid var(--n-300);
    flex-grow: 0;

    .title-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 18px;

      i {
        font-size: 12px;
        color: var(--n-700);
        cursor: pointer;
      }
    }

    input {
      padding-left: 0 !important;
    }
  }

  .events-list {
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    &.is-empty {
      align-items: center;
      padding-top: 20px;
    }

    .event-category {
      padding: 16px 10px 4px 10px;

      .category-title {
        padding: 0 10px;
        margin-bottom: 4px;
        text-transform: capitalize;
      }
      .events-wrapper {
        display: flex;
        flex-direction: column;

        .event {
          display: flex;
          align-items: center;
          gap: 12px;
          cursor: pointer;
          padding: 8px 10px;
          border-radius: 12px;
          &:hover {
            background-color: var(--b-100);
          }

          .icon-wrapper {
            border: 1px solid var(--n-300);
            border-radius: 12px;
            padding: 8px;
            font-size: 18px;
          }

          .event-name-wrapper {
            flex: 1;
          }

          .icon-checkbox-o {
            font-size: 20px;
            color: var(--n-300);
          }
          .icon-checkbox-a {
            font-size: 20px;
            color: var(--b-400);
          }
        }
      }
    }
  }

  .modal-footer {
    padding: 16px;
    border-top: 1px solid var(--n-300);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
