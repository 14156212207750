.resource-center-render-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .checklist-progress-warning {
    display: flex;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 24px;
    width: 320px;
    box-sizing: border-box;
    background: var(--n-100);
    border-radius: 12px;
    padding: 12px 16px;
    gap: 16px;
    border: 1px solid var(--n-400);
    z-index: 1;

    i {
      font-size: 24px;
      margin: auto;
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
  }

  .resource-center-wrapper {
    position: relative;
    max-height: calc(100% - 32px);
    display: flex;

    .resource-center-render-wrapper {
      max-height: 100%;
      display: flex;

      .resource-center-render {
        position: relative;
        overflow: hidden;
        z-index: 1;
        display: flex;
        overflow: visible;
      }
    }
  }
}
