.amplitude-settings-help-section {
  .team-name {
    font-weight: 700;
  }

  .settings-card {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .section {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .section-resources {
      .section-content {
        display: flex;
        flex-direction: column;
        gap: 16px;
        justify-content: center;
        align-items: center;

        a {
          width: 100%;

          .btn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
          }
        }
      }
    }
    .section-disconnect {
      .btn {
        justify-content: center;
      }
    }
  }
}
