.section-item.padding-item {
  &.extended {
    .section-item-content {
      display: grid;
      grid-template-columns: 1fr 1fr;

      i.p-l {
        position: relative;
        &::after {
          display: block;
          position: absolute;
          content: '';
          width: 2px;
          height: 16px;
          background-color: white;
          top: 0px;
          right: 0px;
        }
      }
      i.p-r {
        position: relative;
        &::after {
          display: block;
          position: absolute;
          content: '';
          width: 2px;
          height: 16px;
          background-color: white;
          top: 0px;
          left: 0px;
        }
      }
      i.p-t {
        position: relative;
        &::after {
          display: block;
          position: absolute;
          content: '';
          width: 16px;
          height: 2px;
          background-color: white;
          bottom: 0px;
        }
      }
      i.p-b {
        position: relative;
        &::after {
          display: block;
          position: absolute;
          content: '';
          width: 16px;
          height: 2px;
          background-color: white;
          top: 0px;
        }
      }
    }
  }

  .section-item-title {
    flex: 1;
    display: flex;
    justify-content: space-between;

    i {
      color: var(--n-600);
      margin-left: 4px;
      font-size: 14px;
      padding: 4px;
      border-radius: 4px;
      cursor: pointer;
      &:hover {
        background-color: var(--n-100);
      }
      &.active {
        color: var(--b-500);
      }
    }
  }
  .section-item-content {
    display: flex;
    gap: 8px;

    .input-group {
      flex-grow: 1;
      min-width: 0;

      .label-left {
        .icon-wrapper {
          i {
            font-size: 16px;
            color: var(--n-600);
          }
        }
      }

      .input {
        padding: 4px !important;
      }
    }
  }
}
