.block-resource-center-action-wrapper {
  z-index: 11;
  display: flex;

  // &:not(.poke-block-clickable) {
  .block-resource-center-action {
    &:not(.has-text-cta) {
      cursor: pointer;
    }
  }
  // }

  .block-resource-center-action {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex-grow: 1;
    transition: all 200ms ease-in-out;

    .action-content {
      display: flex;
      flex-direction: column;

      &.is-stack {
        flex-direction: row;
        align-items: center;

        .action-content-body {
          flex-grow: 1;
        }
      }

      &.is-grid {
        .action-content-body {
          display: flex;
          flex-direction: column;

          .action-content-body-header {
            display: flex;
            justify-content: space-between;
            align-self: stretch;
          }

          .action-content-body-text {
            align-self: stretch;
          }
        }
      }

      &.is-standalone {
        .action-content-body-wrapper {
          display: flex;
          flex-direction: column;
        }
      }

      .action-content-body {
        display: flex;
        align-items: center;
        align-self: stretch;

        .action-content-body-text {
          display: flex;
          flex-direction: column;
          gap: 4px;
          flex-grow: 1;
        }
      }
    }
  }
}
