.info-bar {
  display: flex;
  gap: 16px;

  .info-bar-item {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 12px 16px;
    border-radius: 12px;
    background-color: var(--white);

    .info-bar-item-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background-color: var(--b-100);

      i {
        font-size: 20px;
        color: var(--b-400);
      }
    }
  }
}
