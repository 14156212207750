.modal-add-experience-to-space {
  max-height: 80%;
  padding: 0;
  width: 400px;
  max-width: 80%;
  display: flex;
  flex-direction: column;

  .list-spaces-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1 auto;
    min-height: 0;

    .search-wrapper {
      padding: 8px 10px;
    }

    .spaces-wrapper {
      overflow-y: auto;
      overflow-x: hidden;
      display: flex;
      flex-direction: column;
      gap: 4px;
      padding: 8px;

      .space-card {
        width: 100%;
        display: flex;
        gap: 12px;
        border-radius: 8px;
        padding: 8px;
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;
        box-sizing: border-box;

        &:hover {
          background-color: var(--n-100);
        }

        .icon-wrapper {
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          font-size: 20px;
        }

        .checkbox-wrapper {
          display: flex;
          margin: auto 0;
          flex-shrink: 0;
          margin-left: auto;
          i {
            font-size: 20px;

            &.icon-checkbox {
              color: var(--b-400);
            }
            &.icon-checkbox-o {
              color: var(--n-400);
            }
          }
        }
      }
    }
  }
}
