.modal-jimer-profile {
  margin: 8px;
  right: 0 !important;
  top: 0 !important;
  left: unset !important;
  position: absolute;
  height: calc(100% - 16px);
  width: 656px !important;
  display: flex;
  flex-direction: column;
  padding: 0;
  border-radius: 12px;
  overflow: hidden;
}

.jimer-profile-wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-grow: 1;

  .jimer-profile {
    min-height: 0;
    display: flex;
    flex-direction: column;
    padding: 0;
    flex-grow: 1;

    .jimer-banner {
      position: relative;

      .jimer-banner-img {
        width: 100%;
      }

      .avatar-img-wrapper {
        position: absolute;
        margin-right: 16px;
        height: 100px;
        width: 100px;
        left: 24px;
        bottom: -64px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--y-200);
        border: 4px solid var(--white);
        border-radius: 50%;

        .avatar {
          height: 60px;
          width: 60px;
        }
      }
    }

    .jimer-tags-wrapper {
      display: flex;
      padding: 24px;
      padding-bottom: 0;
      margin-left: auto;
    }

    .jimer-details-wrapper {
      display: flex;
      margin-bottom: 12px;
      padding: 24px;
      padding-bottom: 0;

      .back-btn {
        background-color: #f4f4f4;
        height: 40px;
        width: 40px;
        border-radius: 12px;
        padding: 0;
        margin: auto 0;
        margin-right: 16px;

        &:hover {
          background-color: #dddddd;
        }

        i {
          margin: auto !important;
          color: #000000;
        }
      }

      .jimer-details {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .id-wrapper {
          display: flex;
          gap: 4px;
          align-items: center;

          i {
            cursor: pointer;
          }
        }
      }
    }

    .tabs {
      .tab-list {
        padding-left: 24px;
        margin-bottom: 0;

        .tab-item {
          display: flex;
          align-items: center;
          gap: 8px;

          i {
            font-size: 22px;
          }
        }
      }

      .tab-content {
        display: none;
      }
    }

    .tab-content {
      display: flex;
      flex-direction: column;
      gap: 24px;
      background-color: #f7f9fe;
      flex-grow: 1;
      min-height: 0;

      .tab-data-wrapper {
        padding: 24px;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        gap: 24px;
        flex: 1 auto;
      }
    }

    .segments {
      display: flex;
      gap: 8px;

      .attributes-btn {
        padding: 6px 12px;
        border-radius: 26px;
        font-weight: 600;
        font-size: 14px;

        i {
          font-size: 8px;
          margin-left: 8px;
        }
      }

      .segment {
        padding: 6px 12px;
        border-radius: 26px;
        font-weight: 500;
      }
    }
  }

  .loader-wrapper {
    margin: auto;
  }

  .empty-state-wrapper {
    flex: 1 auto;
    display: flex;

    .activity-empty-state {
      margin: auto;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.4);
    }
  }
}
