.timeline-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  overflow-y: auto;
  flex: 1 auto;

  &.no-padding {
    padding: 0;
  }

  .timeline {
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: relative;
    padding-left: 34px;

    .timeline-trait {
      position: absolute;
      z-index: 0;
      left: 15px;
      width: 2px;
      background-color: rgba($color: #000000, $alpha: 0.1);
    }

    .timeline-group {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-bottom: 16px;
      position: relative;

      .timeline-date-header {
        display: flex;
        align-items: center;
        position: relative;
        margin-left: -34px;

        .timeline-dot {
          position: relative;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background-color: var(--n-800);
          margin-right: 12px;
          margin-left: 8px;
          border: 4px solid var(--n-300);
          z-index: 1;
          box-sizing: border-box;
        }
      }

      .timeline-group-items {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
    }
  }
}
