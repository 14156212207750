.block {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  transition: all ease-in-out 100ms;
  border-color: #e6e6e6;
  padding: 12px;
  &.has-no-title {
    .title {
      opacity: 0.3;
    }
  }
  &:hover {
    cursor: pointer;
    border-color: #8fc6ff;
    box-shadow: 0px 0px 0px 1px #8fc6ff;
  }
  &.is-dragging {
    transform: rotate(3deg);
  }
  &.is-disabled {
    opacity: 0.2;
    cursor: default;
  }
  &.is-coming-soon {
    position: relative;
    cursor: default;
    color: rgb(0, 0, 0, 0.2);
    &:hover {
      background-color: #f8fcff !important;
    }

    .icon-wrapper {
      opacity: 0.2;
    }

    &::before {
      position: absolute;
      background-color: rgb(255, 242, 0);
      border-radius: 4px;
      padding: 4px 8px;
      right: 10px;
      z-index: 2;
      top: 8px;
      font-size: 8px;
      text-transform: uppercase;
      font-weight: 800;
      content: 'Coming soon';
      display: block;
      color: #000;
    }
  }
  &.is-beta {
    position: relative;

    &::before {
      position: absolute;
      background-color: rgb(0, 132, 255);
      border-radius: 4px;
      padding: 4px 8px;
      right: 10px;
      z-index: 2;
      top: 8px;
      font-size: 8px;
      text-transform: uppercase;
      font-weight: 800;
      content: 'Beta';
      display: block;
      color: #000;
    }
  }
  &.muted {
    border-color: transparent;
    &:hover {
      background-color: #e6f5fd;

      .icon-wrapper {
        background-color: #fff !important;
      }
    }
  }
  &.selected {
    border-color: #89c2fe;
    box-shadow: 0px 0px 0px 2px #cfe6fe;
    .icon-wrapper {
      background-color: transparent !important;
    }
    .close-btn-wrapper {
      color: #a5a5a5;
    }
    & > .block-content {
      background: #e6f5fd;
    }
  }
  &.has-children {
    padding: 0;
    overflow: hidden;
    &.active {
      border-color: #89c2fe;
      box-shadow: 0px 0px 0px 2px #cfe6fe;
    }

    & > .block-content {
      padding: 12px;
      border-bottom: 1px solid #e6e6e6;
    }
  }
  &.invalid {
    border-color: #ffa5a5;

    .description {
      color: #ffa5a5;
    }
  }
  &.add-block-btn {
    border-color: #208dff;
    border-style: dashed;

    .icon-wrapper {
      background-color: rgb(32, 141, 255, 0.1);
      .icon {
        color: #208dff;
        font-size: 13px;
      }
    }

    .title,
    .description {
      color: #208dff;
    }

    &:hover {
      .icon-wrapper {
        background-color: #208dff;
        .icon {
          color: #1d1f2d;
        }
      }

      .title {
        font-weight: 600;
      }
    }
  }

  .block-content {
    display: flex;
    align-items: center;
    &:hover {
      background-color: #e6f5fd;
    }
  }
  .icon-menu-vertical {
    padding-right: 8px;
    font-size: 12px;
    cursor: grab;
    &:active {
      cursor: grabbing;
    }
  }

  .icon-wrapper {
    display: flex;
    height: 40px;
    width: 40px;
    min-width: 40px;
    border-radius: 12px;
    margin-right: 10px;
    background-color: white;
    transition: all ease-in-out 100ms;
    align-items: center;
    justify-content: center;

    i {
      color: black;
      margin: auto;
      font-size: 24px;
    }

    img {
      height: 20px;
      width: 20px;
      object-fit: contain;
    }
  }

  .content {
    align-self: center;
    flex: 1 1 auto;

    .title {
      font-size: 16px;
    }

    .description {
      font-size: 12px;
    }
  }

  .close-btn-wrapper {
    margin: auto;
    font-size: 12px;
    padding: 8px;
    color: #cfcfcf;

    &:hover {
      color: #000;
    }
  }

  .btn-add-sub-block {
    text-align: center;
    padding: 12px;
    background-color: #fff;
    border-top: 1px solid #e6e6e6;
    &:hover {
      background-color: rgb(250, 250, 250);
    }

    i {
      font-size: 12px;
      margin-left: 6px;
    }
  }
}

.block.sub-block {
  padding: 0;
  border-radius: 0;
  border: none;
  box-shadow: none;
  &:not(:first-child) {
    border-top: 1px solid #e6e6e6;
  }
  .block-content {
    padding: 8px 12px;
  }
  .title {
    font-size: 14px;
  }
  .icon-wrapper {
    display: flex;
    height: 30px;
    width: 30px;
    min-width: 30px;
    border-radius: 12px;
    margin-right: 10px;
    margin-left: 10px;
    background-color: white;
    transition: all ease-in-out 100ms;
    align-items: center;
    justify-content: center;

    i {
      color: black;
      margin: auto;
      font-size: 18px;
    }
  }
  &.invalid {
    background-color: #ffecec;

    .description {
      color: #ff8282;
    }
  }
}
