.resource-center-layout {
  display: flex;
  flex-direction: column;
  overflow: visible;
  align-self: stretch;
  min-height: 0;

  .resource-center-header {
    display: flex;
    gap: 16px;
    width: 100%;
    box-sizing: border-box;
    z-index: 1000;

    .resource-center-header-content {
      flex: 1;
      transition: transform 0.3s ease-out;
      max-width: 70%;
      transform-origin: left center;
    }

    .resource-center-header-actions {
      flex: 0;
      margin-left: auto;
    }
  }

  .resource-center-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;
    overflow-x: hidden;
    overflow-y: auto;
    // hack to make clickable overlay appear for first item
    padding-top: 64px;
    margin-top: -4px;
  }

  .jimo-label-wrapper {
    z-index: 12;
    position: absolute;
    bottom: 12px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 32px;
    overflow: hidden;
    box-shadow: 0px 44px 44px -16px #2a33460f, 0px 12px 12px -6px #2a33460a,
      0px 6px 6px -3px #2a33460a, 0px 3px 3px -1.5px #2a33460a,
      0px 1px 1px -0.5px #2a33450a, 0px 0px 0px 1px #0e3f7e0a;

    .poke-renderer-poke-label {
      .poke-label-content {
        padding: 8px 12px;
      }
    }
  }
}
