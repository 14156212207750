.block-resource-center-action-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 12;
  flex-shrink: 0;
  height: fit-content;

  .icon {
    display: flex;
    height: 100%;
    width: 100%;

    svg,
    img {
      margin: auto;
      width: 100%;
      height: 100%;
    }
  }
}
