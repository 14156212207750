.space-experiences {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;

  .loader-wrapper {
    margin: auto;
  }
}
