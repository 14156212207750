.changelog-builder-sidebar-wrapper {
  height: 100%;
  display: flex;
  z-index: 2;
  min-height: 0;

  .changelog-builder-sidebar {
    flex-shrink: 0;
    background: #fff;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 56px;
    border-right: 1px solid #dad9db;
    gap: 8px;
    padding-top: 16px;

    .sidebar-item {
      cursor: pointer;
      margin: 0 auto;
      height: 40px;
      width: 40px;
      display: flex;
      border-radius: 8px;
      transition: all 150ms ease-in-out;
      border: 2px solid transparent;

      &:hover {
        background: rgba(18, 96, 235, 0.1);
        border: 2px solid #1260eb;
        color: #1260eb;
      }
      &.selected {
        background-color: #1260eb;
        color: #fff;
      }

      i {
        margin: auto;
        font-size: 24px;
      }
    }
  }

  .changelog-builder-sidebar-content-wrapper {
    display: flex;
    flex-grow: 1;
    min-width: 0;
  }
}
