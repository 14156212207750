.modal-edit-space {
  max-height: 80%;
  padding: 0;
  width: 480px;
  display: flex;
  flex-direction: column;

  &.mode-add-experiences {
    width: 1020px;
    max-width: 80%;
  }

  .modal-edit-space-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px 16px;
    min-height: 0;

    .color-and-icon-wrapper {
      display: flex;
      gap: 16px;
      align-items: center;

      .icon-wrapper {
        display: flex;
        height: 84px;
        width: 84px;
        border-radius: 100%;
        align-items: center;
        justify-content: center;
        font-size: 36px;
        cursor: pointer;
      }

      .color-picker-wrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .color-picker {
          display: flex;
          gap: 8px;

          .color {
            display: flex;
            height: 32px;
            width: 32px;
            border-radius: 100%;
            border: 1px solid transparent;
            cursor: pointer;
            transition: border-color 0.2s;

            &:hover,
            &.selected {
              border-color: var(--n-800);
            }
          }
        }
      }
    }

    .form-section {
      display: flex;
      flex-direction: column;
      gap: 4px;

      &.domains-section {
        min-height: 0;
      }

      .label {
        display: flex;
        align-items: center;
        gap: 8px;
      }

      .input-group {
        height: 40px;
      }

      .input-description {
        textarea {
          resize: none;
          padding: 12px;
          border-radius: 12px;
          font-size: 16px;
          font-weight: 500;
          font-family: Inter;
          height: 85px;
        }
      }
    }
  }
}
