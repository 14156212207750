.pushes-view-editor {
  display: flex;
  padding: 0;
  gap: 16px;
  margin-top: 16px;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px;
  padding-bottom: 16px;

  .dropdown-trigger {
    &.is-set {
      .btn {
        border-color: #1260eb66;
        background-color: #1260eb0f;
        color: #1260eb;

        i {
          color: #1260eb !important;
        }
      }
    }

    i.icon-right {
      font-size: 12px !important;
      color: var(--n-800);
      margin-left: 10px !important;
    }

    .view-setting-btn {
      border-radius: 12px;
    }
  }
  .view-settings {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  .divider {
    height: 16px;
  }
  .view-layouts {
    display: flex;
    gap: 8px;
    .btn {
      &.is-set {
        border-color: #1260eb66;
        background-color: #1260eb0f;
        color: #1260eb;

        i {
          color: #1260eb !important;
        }
      }
      padding: 6px 12px;
    }
  }
  .view-actions {
    display: flex;
    gap: 16px;

    .btn {
      &:not(.is-light) {
        border-color: #f89400;
        background-color: #f8940030;
        color: #f89400;
        &:hover {
          background-color: #f8950049 !important;
          color: #f89400 !important;
        }
        &:active {
          background-color: #f8940030 !important;
        }
      }
    }
  }
}

.view-setting-content {
  min-width: 158px;
  background: #ffffff;
  box-shadow: 0px 14px 19px rgb(0 0 0 / 3%);
  border-radius: 8px;
  margin: 4px auto;

  .setting-list {
    max-height: 200px;
    padding: 4px;
    overflow-y: auto;

    .list-empty-state {
      opacity: 0.3;
      padding: 8px;
      font-size: 12px;
      text-align: center;
    }

    .setting-item {
      display: flex;
      align-items: center;
      padding: 8px;
      font-weight: 400;
      font-size: 14px;
      border-radius: 8px;
      font-family: 'Montserrat';
      font-weight: 500;
      cursor: pointer;
      &:hover {
        background-color: #1260eb0f;
      }
      &.is-set {
        color: #1260eb;
      }

      i {
        margin-right: 8px;
        font-size: 14px;
      }
    }
  }
}

.view-tags-content {
  .setting-list {
    padding: 8px;
  }
  .setting-item {
    transition: all 100ms ease;
    opacity: 0.5;
    &:not(:last-child) {
      margin-bottom: 8px;
    }
    &:hover {
      opacity: 1;
    }
    &.is-set {
      opacity: 1;
    }
  }
}

.view-environments-content {
  .setting-list {
    padding: 8px;
  }
  .setting-item {
    display: flex;
    align-items: center;
    gap: 8px;
    transition: all 100ms ease;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
    &:hover {
      opacity: 1;
    }
    &.is-set {
      color: #1260eb;
    }

    .icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      height: 20px;
      width: 20px;
      font-size: 12px;
    }
  }
}
