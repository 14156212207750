.poke-render-zoom-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .poke-wrapper {
    position: relative;

    .poke-render {
      position: relative;
      // background-color: #fff;
      overflow: hidden;
      z-index: 1;

      &:not(.auto-height) {
        .jimo-poke {
          height: 100%;
        }
      }
    }
  }
}

.hotspot-preview {
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  cursor: pointer;

  &.animation-pulse {
    animation: dot-pulse 1.2s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }
  // when no colors provided to box-shadow, the property color is used
}

.hint-wrapper {
  position: absolute;

  &.is-button {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .hint-icon-preview {
    display: flex;
    justify-content: center;
    align-items: center;

    .pulsating-circle {
      border-radius: 50%;
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      position: absolute;
      box-shadow: 0px 0px 1px 1px #0000001a;
      animation: pulse-animation 0.9s infinite;
      z-index: -1;
    }

    svg {
      -webkit-transform: translate(0px, 0px);
      transform: translate(0px, 0px);
      backface-visibility: hidden;
      -webkit-backface-visibility: hidden;
      width: 100%;
      height: 100%;
    }
  }

  .hint-hidden-preview {
    display: flex;
    padding: 12px 16px;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px dashed var(--border-moderate, #9ca1ad);
  }

  @keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.4);
    }
    100% {
      box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
  }

  .hint-button-preview,
  .hint-label-preview {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    .inner {
      position: relative;
      z-index: 1;
      width: 100%;
    }
  }
}

.poke-render-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

/* classes for animations */
/* fade */
.poke-animation-fade-in {
  animation: poke-fade-in 1000ms ease-in-out forwards;
}
.poke-animation-fade-out {
  animation: poke-fade-out 1000ms ease-in-out backwards;
}
/* slide */
.poke-animation-slide-right-in {
  animation: poke-slide-right-in 1000ms ease-in-out forwards;
}
.poke-animation-slide-right-out {
  animation: poke-slide-right-out 1000ms ease-in-out backwards;
}
.poke-animation-slide-left-in {
  animation: poke-slide-left-in 1000ms ease-in-out forwards;
}
.poke-animation-slide-left-out {
  animation: poke-slide-left-out 1000ms ease-in-out backwards;
}
.poke-animation-slide-down-in {
  animation: poke-slide-down-in 1000ms ease-in-out forwards;
}
.poke-animation-slide-down-out {
  animation: poke-slide-down-out 1000ms ease-in-out backwards;
}
.poke-animation-slide-up-in {
  animation: poke-slide-up-in 1000ms ease-in-out forwards;
}
.poke-animation-slide-up-out {
  animation: poke-slide-up-out 1000ms ease-in-out backwards;
}

/** For poke **/
/* Slide */
@-webkit-keyframes poke-slide-left-in {
  0% {
    opacity: 0;
    transform: matrix(0.85, 0, 0, 0.85, -200, 0);
  }
  50% {
    opacity: 1;
    transform: matrix(0.85, 0, 0, 0.85, 0, 0);
  }
  100% {
    transform: matrix(1, 0, 0, 1, 0, 0);
    opacity: 1;
  }
}
@keyframes poke-slide-left-in {
  0% {
    transform: matrix(0.85, 0, 0, 0.85, -200, 0);
    opacity: 0;
  }
  50% {
    transform: matrix(0.85, 0, 0, 0.85, 0, 0);
    opacity: 1;
  }
  100% {
    transform: matrix(1, 0, 0, 1, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes poke-slide-left-out {
  0% {
    transform: matrix(1, 0, 0, 1, 0, 0);
    opacity: 1;
  }
  50% {
    transform: matrix(0.85, 0, 0, 0.85, 0, 0);
    opacity: 1;
  }
  100% {
    transform: matrix(0.85, 0, 0, 0.85, -200, 0);
    opacity: 0;
  }
}
@keyframes poke-slide-left-out {
  0% {
    transform: matrix(1, 0, 0, 1, 0, 0);
    opacity: 1;
  }
  50% {
    transform: matrix(0.85, 0, 0, 0.85, 0, 0);
    opacity: 1;
  }
  100% {
    transform: matrix(0.85, 0, 0, 0.85, -200, 0);
    opacity: 0;
  }
}
@-webkit-keyframes poke-slide-right-in {
  0% {
    opacity: 0;
    transform: matrix(0.85, 0, 0, 0.85, 200, 0);
  }
  50% {
    opacity: 1;
    transform: matrix(0.85, 0, 0, 0.85, 0, 0);
  }
  100% {
    transform: matrix(1, 0, 0, 1, 0, 0);
    opacity: 1;
  }
}
@keyframes poke-slide-right-in {
  0% {
    transform: matrix(0.85, 0, 0, 0.85, 200, 0);
    opacity: 0;
  }
  50% {
    transform: matrix(0.85, 0, 0, 0.85, 0, 0);
    opacity: 1;
  }
  100% {
    transform: matrix(1, 0, 0, 1, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes poke-slide-right-out {
  0% {
    transform: matrix(1, 0, 0, 1, 0, 0);
    opacity: 1;
  }
  50% {
    transform: matrix(0.85, 0, 0, 0.85, 0, 0);
    opacity: 1;
  }
  100% {
    transform: matrix(0.85, 0, 0, 0.85, 200, 0);
    opacity: 0;
  }
}
@keyframes poke-slide-right-out {
  0% {
    transform: matrix(1, 0, 0, 1, 0, 0);
    opacity: 1;
  }
  50% {
    transform: matrix(0.85, 0, 0, 0.85, 0, 0);
    opacity: 1;
  }
  100% {
    transform: matrix(0.85, 0, 0, 0.85, 200, 0);
    opacity: 0;
  }
}
@-webkit-keyframes poke-slide-up-in {
  0% {
    opacity: 0;
    transform: matrix(0.85, 0, 0, 0.85, 0, -200);
  }
  50% {
    opacity: 1;
    transform: matrix(0.85, 0, 0, 0.85, 0, 0);
  }
  100% {
    transform: matrix(1, 0, 0, 1, 0, 0);
    opacity: 1;
  }
}
@keyframes poke-slide-up-in {
  0% {
    transform: matrix(0.85, 0, 0, 0.85, 0, -200);
    opacity: 0;
  }
  50% {
    transform: matrix(0.85, 0, 0, 0.85, 0, 0);
    opacity: 1;
  }
  100% {
    transform: matrix(1, 0, 0, 1, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes poke-slide-up-out {
  0% {
    transform: matrix(1, 0, 0, 1, 0, 0);
    opacity: 1;
  }
  50% {
    transform: matrix(0.85, 0, 0, 0.85, 0, 0);
    opacity: 1;
  }
  100% {
    transform: matrix(0.85, 0, 0, 0.85, 0, -200);
    opacity: 0;
  }
}
@keyframes poke-slide-up-out {
  0% {
    transform: matrix(1, 0, 0, 1, 0, 0);
    opacity: 1;
  }
  50% {
    transform: matrix(0.85, 0, 0, 0.85, 0, 0);
    opacity: 1;
  }
  100% {
    transform: matrix(0.85, 0, 0, 0.85, 0, -200);
    opacity: 0;
  }
}
@-webkit-keyframes poke-slide-down-in {
  0% {
    opacity: 0;
    transform: matrix(0.85, 0, 0, 0.85, 0, 200);
  }
  50% {
    opacity: 1;
    transform: matrix(0.85, 0, 0, 0.85, 0, 0);
  }
  100% {
    transform: matrix(1, 0, 0, 1, 0, 0);
    opacity: 1;
  }
}
@keyframes poke-slide-down-in {
  0% {
    transform: matrix(0.85, 0, 0, 0.85, 0, 200);
    opacity: 0;
  }
  50% {
    transform: matrix(0.85, 0, 0, 0.85, 0, 0);
    opacity: 1;
  }
  100% {
    transform: matrix(1, 0, 0, 1, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes poke-slide-down-out {
  0% {
    transform: matrix(1, 0, 0, 1, 0, 0);
    opacity: 1;
  }
  50% {
    transform: matrix(0.85, 0, 0, 0.85, 0, 0);
    opacity: 1;
  }
  100% {
    transform: matrix(0.85, 0, 0, 0.85, 0, 200);
    opacity: 0;
  }
}
@keyframes poke-slide-down-out {
  0% {
    transform: matrix(1, 0, 0, 1, 0, 0);
    opacity: 1;
  }
  50% {
    transform: matrix(0.85, 0, 0, 0.85, 0, 0);
    opacity: 1;
  }
  100% {
    transform: matrix(0.85, 0, 0, 0.85, 0, 200);
    opacity: 0;
  }
}
@-webkit-keyframes poke-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes poke-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes poke-fade-out {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes poke-fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes hintStickerScaleInOut {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}
