.recent-uploads-wrapper {
  position: relative;
  font-size: 12px;
  &.has-tiny-thumbnails {
    .recent-uploads-list {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      gap: 8px;

      .recent-upload {
        height: 30px;
        width: 30px;

        .icon-wrapper {
          height: 10px;
          width: 10px;
          top: -8px;
          right: -8px;
          padding: 2px;

          i {
            font-size: 12px;
          }
        }
      }
    }

    .delete-recent-file-wrapper {
      gap: 0;

      .text-wrapper {
        flex: 1;
      }
    }
  }

  .delete-recent-file-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(4px);
    width: 100%;
    height: 100%;
    z-index: 2;
    border: 1px solid var(--n-300);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 6px;
    box-sizing: border-box;
    justify-content: center;
    gap: 12px;

    .actions-wrapper {
      display: flex;
      justify-content: space-between;
    }
  }

  .recent-label {
    margin-bottom: 8px;
  }

  .recent-uploads-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;

    .recent-upload {
      height: 56px;
      width: 122px;
      border-radius: 8px;
      border: 2px solid transparent;
      box-sizing: border-box;
      position: relative;

      &.selected {
        border-color: #1260eb !important;
      }

      &:not(.placeholder):hover {
        border-color: rgba(0, 0, 0, 0.15);

        .icon-wrapper {
          display: flex;
        }
      }

      .recent-upload-placeholder {
        height: 100%;
        width: 100%;
        object-fit: cover;
        background: rgba(0, 0, 0, 0.05);
        border-radius: 8px;
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 8px;
        cursor: pointer;
      }

      .icon-wrapper {
        position: absolute;
        height: 16px;
        width: 16px;
        top: -8px;
        right: -8px;
        background: #fff;
        padding: 2px;
        border: 1px solid var(--n-300);
        border-radius: 4px;
        display: none;
        cursor: pointer;

        i {
          font-size: 16px;
          color: var(--r-400);
        }
      }
    }
  }
}
