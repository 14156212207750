@import './components/Navbar/Styles';

.basic-layout {
  display: flex;
  position: relative;
  &.animated {
    animation: zoom-out 0.8s ease-in;
  }

  .layout-onboarding-arrival {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 10000000;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: visible;
    &.is-exiting {
      animation: layout-onboarding-arrival-out 1000ms ease-in-out both;
    }

    .jimo-loader-wrapper {
      width: 120px;
      height: 120px;
    }
  }

  .bl-component-wrapper {
    height: 100%;
    flex: 1 auto;
    overflow: hidden;

    &.has-settings-menu {
      display: flex;
      padding-bottom: 16px;
    }

    .settings-menu {
      flex: 0 0 250px;
      margin-right: 16px;
    }
  }
}

@-webkit-keyframes layout-onboarding-arrival-out {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  100% {
    visibility: hidden;
    opacity: 0;
  }
}
@keyframes layout-onboarding-arrival-out {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  100% {
    visibility: hidden;
    opacity: 0;
  }
}

.onboarding-layout {
  height: 100%;
  padding: 16px;
  display: flex;
  box-sizing: border-box;
  position: relative;

  &.is-in-app {
    padding: 0;
    width: 500px;
    max-height: 700px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 2.8px 2.2px rgb(0 0 0 / 3%), 0 6.7px 5.3px rgb(0 0 0 / 5%),
      0 12.5px 10px rgb(0 0 0 / 6%), 0 22.3px 17.9px rgb(0 0 0 / 7%),
      0 41.8px 33.4px rgb(0 0 0 / 9%), 0 100px 80px rgb(0 0 0 / 12%);
    border-radius: 16px;
  }

  .main-wrapper {
    background-color: #fff;
    border-radius: 16px;
    flex: 1;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    z-index: 1;
    &.anim-in {
      animation: main-wrapper-entry 1800ms ease-in-out both 200ms;
    }
    &.anim-details-in {
      transform: matrix(0.95, 0, 0, 0.95, 0, 0);
      animation: anim-details-in 600ms ease-in-out both 1800ms;
    }
    &.expanded {
      width: 100%;
      justify-content: unset;
      padding-left: 24px;
    }
    &.grid-a-b-a {
      display: grid;
      grid-template-columns: 300px auto 300px;
      box-sizing: border-box;
    }
    &.grid-a-b {
      display: grid;
      grid-template-columns: 100px calc(100% - 100px);
      box-sizing: border-box;
    }

    .s-onboarding-details-step {
      position: relative;
      z-index: 3;
      overflow-y: auto;
      overflow-x: hidden;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .onboarding-details-animation {
      position: absolute;
      top: 400px;
      left: -500px;
      width: 100%;
      height: 100%;
      max-width: 1200px;
      z-index: 0;
      animation: onboarding-animation-in 500ms ease-in-out both;
      pointer-events: none;

      &.animation-out {
        animation: onboarding-animation-out 500ms ease-in-out forwards;
      }
      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }
  .testimonials-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40%;
    max-width: 600px;
    &.is-exiting {
      animation: onboarding-testimonials-out 2000ms ease-in-out forwards;
    }

    @media (max-width: 600px) {
      display: none;
    }
  }
}

.onboarding-layout.anim-from-register-to-details {
  animation: from-register-to-details 2000ms ease-in-out forwards;
}

.onboarding-layout.anim-onboarding-end {
  animation: onboarding-end 1000ms ease-in-out both 800ms;

  .main-wrapper {
    animation: onboarding-end-main-wrapper 1000ms ease-in-out both 800ms;
  }
  .onboarding-progression-tracker {
    animation: onboarding-end-pt 400ms ease-in-out both;
  }
}

@-webkit-keyframes main-wrapper-entry {
  0% {
    transform: matrix(0.95, 0, 0, 0.95, 0, 0);
    opacity: 0;
  }
  50% {
    transform: matrix(0.95, 0, 0, 0.95, 0, 0);
    opacity: 1;
  }
  100% {
    transform: matrix(1, 0, 0, 1, 0, 0);
    opacity: 1;
  }
}
@keyframes main-wrapper-entry {
  0% {
    transform: matrix(0.95, 0, 0, 0.95, 0, 0);
    opacity: 0;
  }
  50% {
    transform: matrix(0.95, 0, 0, 0.95, 0, 0);
    opacity: 1;
  }
  100% {
    transform: matrix(1, 0, 0, 1, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes from-register-to-details {
  0% {
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
  100% {
    transform: matrix(0.95, 0, 0, 0.95, 0, 0);
  }
}
@keyframes from-register-to-details {
  0% {
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
  100% {
    transform: matrix(0.95, 0, 0, 0.95, 0, 0);
  }
}
@-webkit-keyframes anim-details-in {
  0% {
    transform: matrix(0.95, 0, 0, 0.95, 0, 0);
  }
  100% {
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
}
@keyframes anim-details-in {
  0% {
    transform: matrix(0.95, 0, 0, 0.95, 0, 0);
  }
  100% {
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
}
@-webkit-keyframes onboarding-end {
  0% {
    padding: inherit;
    border-radius: inherit;
  }
  100% {
    padding: 0px;
    border-radius: 0px;
  }
}
@keyframes onboarding-end {
  0% {
    padding: 16px;
  }
  100% {
    padding: 0px;
  }
}
@-webkit-keyframes onboarding-end-main-wrapper {
  0% {
    border-radius: 16px;
  }
  100% {
    border-radius: 0px;
  }
}
@keyframes onboarding-end-main-wrapper {
  0% {
    border-radius: 16px;
  }
  100% {
    border-radius: 0px;
  }
}
@-webkit-keyframes onboarding-end-pt {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes onboarding-end-pt {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes onboarding-animation-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes onboarding-animation-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes onboarding-animation-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes onboarding-animation-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.builder-layout {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  .builder-header {
    flex: 0 1 auto;
  }
  .builder-component {
    flex: 1 1 auto;
    overflow: hidden;
    position: relative;
  }
  .builder-component-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    // background-color: rgba($color: rgb(73, 73, 73), $alpha: 0.4);
    background-color: rgba($color: #fff, $alpha: 1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 10;

    .loader {
      margin-bottom: 8px;
    }
  }
}

.settings-layout {
  display: flex;
  height: 100%;

  .settings-navbar-wrapper {
    flex: 0 0 336px;
    background-color: #fff;
    display: flex;
    height: 100%;

    & > a > button,
    & > .go-back-btn {
      position: absolute;
      left: 24px;
      top: 18px;
    }
  }
  .settings-content {
    flex: 1 1 auto;
    height: 100%;
    overflow-y: auto;
    position: relative;
    background-color: #f7f9fe;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    &:not(.no-padding) {
      padding: 32px;
    }
    &.no-bottom-padding {
      padding-bottom: 0;
    }

    .settings-body {
      flex: 1;
      padding: 0;
      width: 100%;
      max-width: 1000px;
    }
  }
  .settings-card {
    background-color: #fff;
    padding: 24px;
    border-radius: 16px;
    box-shadow: 0px 0px 94px 0px rgba(0, 0, 0, 0.04);
  }
}

.templates-layout {
  display: flex;
  height: 100%;

  .templates-navbar-wrapper {
    background-color: #fff;
    display: flex;
    height: 100%;
  }
  .templates-content {
    flex: 1 1 auto;
    height: 100%;
    overflow-y: auto;
    position: relative;
    background-color: var(--b-100);
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}

@keyframes zoom-out {
  0% {
    transform: scale(1.14);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
