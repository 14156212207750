.jimer-section {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: var(--white);
  border-radius: 16px;
  padding: 20px;
  box-shadow: 0px 0px 94px 0px #0000000a;
}

.jimer-segments-list {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;

  .segment {
    padding: 6px 12px;
    border-radius: 16px;
    align-items: center;
    gap: 4px;
  }
}
