.space-experience-preview {
  width: 150px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 12px;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  background: var(--white);
  border-radius: 12px;
  border: 1px solid var(--n-100);

  &:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .loader {
    margin: auto;
  }

  .tag-view {
    padding: 8px;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 500;
  }

  .preview {
    display: flex;
    width: 100%;
    height: 94px;
    flex-shrink: 0;
    position: relative;
    border-radius: 8px;
    background: #e6e6e6;
    transition: all 100ms ease-in;
    transform: scale(1);
    overflow: hidden;

    .empty-steps {
      font-size: 8px;
    }

    .poke-status-tag-wrapper {
      .status-tag {
        padding: 4px 8px;
        font-size: 10px;
        border-radius: 6px;
      }
    }

    .empty-steps {
      margin: auto;
    }

    .poke-preview-icon-wrapper {
      padding: 8px;
      position: absolute;
      background: rgba(0, 0, 0, 0.3);
      border-radius: 8px;
      right: 8px;
      bottom: 8px;
      color: #fff;
      display: none;
    }

    .live-preview {
      position: absolute;
      margin: auto;
      transform: scale(0.8);
      width: 100%;
      height: 100%;
    }
  }

  .infos {
    .experience-title-wrapper {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .experience-title {
        align-items: center;
        display: flex;
        gap: 8px;
        overflow-wrap: anywhere;
        font-size: 10px;
      }
    }

    .poke-card-type {
      .icon-wrapper {
        height: 14px;
        width: 14px;
        border-radius: 4px;

        i {
          font-size: 8px;
        }
      }

      .type-name {
        font-size: 8px;
      }
    }
  }
}
