.block-resource-center-trigger {
  overflow: visible;
  position: absolute !important;
  bottom: 0;
  right: 0;
  transform-origin: bottom right;
  display: none;
  cursor: pointer;
  transition: width 700ms ease;

  &.is-left {
    left: 0;
    right: auto;
    transform-origin: bottom left;
    flex-direction: row-reverse;

    .block-resource-center-trigger-content {
      flex-direction: row-reverse;

      .trigger-hidden-content-container-wrapper {
        .trigger-hidden-content-container {
          flex-direction: row-reverse;
        }
      }
    }
  }

  &.is-mini {
    display: flex;

    &:not(.disable-animations) {
      animation: blurIn 400ms ease forwards;
    }
  }

  &.is-compact {
    .block-resource-center-trigger-content {
      overflow: hidden;

      .trigger-hidden-content-container-wrapper {
        .trigger-hidden-content-container {
          opacity: 0;
          z-index: 2;
          position: relative;
        }
      }

      .block-resource-center-trigger-checklist-progress-wrapper {
        opacity: 1;
      }
    }

    &:hover {
      .trigger-hidden-content-container-wrapper {
        .trigger-hidden-content-container {
          opacity: 1;
        }
      }

      .block-resource-center-trigger-checklist-progress-wrapper {
        opacity: 0;
      }
    }

    &:not(:hover) {
      .block-resource-center-trigger-content {
        .block-resource-center-trigger-icon {
          background-color: transparent !important;
        }
      }
    }
  }

  &.is-expanded {
    &.has-checklist-progress {
      &:hover {
        .block-resource-center-trigger-content {
          .block-resource-center-trigger-checklist-progress-wrapper {
            opacity: 0;
            pointer-events: none;
          }
        }
      }

      &:not(:hover) {
        .trigger-hidden-content-container-wrapper {
          .trigger-hidden-content-container {
            opacity: 0;
            z-index: 2;
            position: relative;
          }
        }

        .block-resource-center-trigger-content {
          .block-resource-center-trigger-checklist-progress-wrapper {
            opacity: 1;
            pointer-events: auto;
          }
        }
      }
    }
  }

  &:not(.has-checklist-progress) {
    .block-resource-center-trigger-content {
      .block-resource-center-trigger-checklist-progress-wrapper {
        opacity: 0;
        pointer-events: none;
      }
    }
  }

  .block-resource-center-trigger-content {
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;
    transition: gap 700ms ease;
    z-index: 11;
    width: 100%;

    .block-resource-center-trigger-icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .trigger-hidden-content-container-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      .trigger-hidden-content-container {
        display: flex;
        align-items: center;
        overflow: visible;
        transition: opacity 700ms ease;
        opacity: 1;
      }

      .block-resource-center-trigger-checklist-progress-wrapper {
        opacity: 0;
        pointer-events: none;
      }
    }
  }
}

@keyframes blurIn {
  0% {
    filter: blur(10px);
  }
  100% {
    filter: blur(0);
  }
}
