.picker-icon-popup-content {
  .search-input {
    .input-main-wrapper {
      height: 40px;

      .label-text-left {
        display: flex;
      }
    }
  }

  .picker-icon-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    height: 300px;
    box-sizing: border-box;

    .phosphor-list,
    .custom-list,
    .twemoji-list {
      align-items: flex-start;
      align-content: flex-start;
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: 16px 0;
      align-self: stretch;
      flex-wrap: wrap;
      flex-grow: 1;
      min-height: 0;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 0 1px;

      .item-icon-wrapper {
        flex: 1;
        min-width: 0;
        max-width: none !important;
        width: 100%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        gap: 8px;
        padding: 0 1px;

        &.selected {
          .item-icon {
            box-shadow: 0 0 0 1px var(--b-300, #85b1ff);
            position: relative;
            z-index: 1;
          }

          .label {
            color: var(--b-400, #1260eb);
          }
        }

        .item-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 16px;
          background: var(--n-100, #f0f2f4);
          cursor: pointer;
          width: 100%;
          min-width: 0;
          box-sizing: border-box;

          i {
            font-size: 24px;
          }

          img,
          svg {
            width: 32px;
            height: 32px;
          }
        }

        .label {
          margin: auto;
          text-align: center;
          display: -webkit-box;
          max-width: 200px;
          -webkit-line-clamp: 3;
          line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }

    .phosphor-list {
      .item-icon {
        padding: 12px 0;
      }
    }

    .custom-list {
      .item-icon {
        padding: 16px 0;
      }
    }

    .twemoji-list {
      .item-icon {
        padding: 16px 0;
      }
    }

    .loading-wrapper {
      flex-grow: 1;
      display: flex;
      align-self: stretch;

      .loader {
        margin: auto;
      }
    }

    .empty-state-wrapper {
      flex-grow: 1;
      display: flex;
      text-align: center;
      align-items: center;
      margin: 24px;
    }

    .action-wrapper {
      display: flex;
      gap: 16px;
      align-self: stretch;

      .btn {
        flex: 1 1 0;
        justify-content: center;
      }
    }
  }
}
