.block-settings.resource-center-trigger-text {
  .select-group-wrapper {
    .jimo-select__single-value {
      span:last-child {
        display: none;
      }
    }
  }
}

.behavior-trigger-text-select {
  .jimo-select__menu {
    right: 0;

    .jimo-select__menu-list {
      max-width: 250px;

      .jimo-select__option {
        display: flex;
        flex-direction: column;
        gap: 2px;
        align-items: flex-start;
      }
    }
  }
}
